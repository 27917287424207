import { Modal } from 'react-bootstrap'
import { Close } from '../../assets/icons/close'


import './style.scss'

/**
* @author
* @function ModalComponent
**/

export const ModalComponent = (props) => {
    const { show, onClose, size, heading, children, backdrop = "static", hideClose = false } = props;
    return (
        <Modal show={show} onHide={onClose} backdrop={backdrop} aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false} size={size}  >
            <Modal.Header>
                <Modal.Title>{heading}</Modal.Title>
                {!hideClose && <a className="close-div" onClick={onClose}>
                    <Close fill="grey" className="close-icon" />
                </a>
                }

            </Modal.Header>
            <Modal.Body  >
                {children}
            </Modal.Body>
        </Modal>
    );
}

export const TicketModal = (props) => {
    const { show, onClose, size, children, backdrop = "static" } = props;
    return (
        <Modal show={show} onHide={onClose} backdrop={backdrop} aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false} size={size} >
            {children}
        </Modal>
    );
}


// export const PackageModal = (props) => {
//     const { show, onClose, size, heading, children, backdrop = "static" } = props;
//     return (
//         <Modal show={show} onHide={onClose} backdrop={backdrop} aria-labelledby="contained-modal-title-vcenter"
//             centered
//             keyboard={false} size={size}  >
//             <Modal.Header>
//                 <Modal.Title>{heading}</Modal.Title>
//                 <a className="close-div" onClick={onClose}>
//                     <Close fill="grey" className="close-icon" />
//                 </a>
//             </Modal.Header>
//             <Modal.Body  >
//                 {children}
//             </Modal.Body>
//         </Modal>
//     );
// }

