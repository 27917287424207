import { useLocation } from 'react-router-dom'


export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const getBookingTimeLimit = () => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + 600);
    return time;
}

export const capitalize = (text) => {

    return text.toLowerCase().replace(/\b\w/g, function (m) { return m.toUpperCase(); });
}

export const getTotalPrice = (selectedTickets) => {
    let sum = 0;
    selectedTickets.forEach((ticket) => {
        sum += ticket.numberOfTickets * ticket.price
    })
    return sum
}

export const getTotalTicketNumber = (selectedTickets) => {
    let count = 0;
    selectedTickets.forEach((ticket) => {
        count += ticket.numberOfTickets
    })
    return count
}


export const setGTagScripts = (trackingId) => {
    let gtagScript1 = document.createElement('script');
    gtagScript1.setAttribute('async', 'true');
    gtagScript1.setAttribute(
        'src',
        `https://www.googletagmanager.com/gtag/js?id=${trackingId}`
    );
    let gtagScript2 = document.createElement('script');
    gtagScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${trackingId}\');`;
    document.documentElement.firstChild.appendChild(gtagScript1);
    document.documentElement.firstChild.appendChild(gtagScript2);
}

