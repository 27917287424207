import axios from "axios";

import { BASE_URL, getAccessToken } from "./";

export const callAPI = (
  url,
  method,
  params = {},
  header = {},
  data = {},
  withAccessToken = true,
  skipBase = false,
  withCredentials = false,
) => {
  return new Promise((resolve, reject) => {
    getAccessToken(withAccessToken)
      .then(token => {

        const headers = {
          ...header,
          'content-type': 'application/json',
        }
        if (withAccessToken) {
          headers.Authorization = 'Bearer ' + token;
        }
        const options = {
          method,
          baseURL: skipBase ? url : BASE_URL,
          headers,
          params,
          data,

          url: !skipBase ? url : ''
        };

        if (withCredentials) {
          options.withCredentials = withCredentials;
        }

        axios(options)
          .then(({ data }) => {
            if (data.status === 'success' || data.status === 200) {
              resolve(data);
            }
            else {
              reject({
                status: "error",
                message: data.message ? data.message : "Something went wrong. Please retry"
              });
            }
          })
          .catch(({ response = {} }) => {
            const { data = {} } = response;
            reject({
              status: "error",
              message: data.errors && data.errors.message ? data.errors.message : "Something went wrong."
            });
          });
      })
      .catch(error => {
        reject({ status: "error", message: error });
      });
  });
};

export const callXHR = (
  url,
  method,
  params,
  responseType = 'arraybuffer',
) => {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    let fullUrl = BASE_URL + url;
    xhr.open(method, fullUrl);
    xhr.responseType = responseType;
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onload = function () {
      if (this.status === 200) {
        resolve({ message: 'success' });
        var blob = new Blob([xhr.response], {
          type: params.attendeeCount > 1 ? "application/zip" : "application/pdf"
        });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = params.attendeeCount > 1 ? params.eventName + " Tickets.zip" : params.eventName + " Ticket.pdf";
        document.body.appendChild(link);
        link.click();
      } else {
        reject({ status: "error", message: 'Error occured. Please try later.' });
      }
    };
    xhr.send();
  })
}




