import { ActionType } from '../helpers'


export const setLoader = payload => dispatch => {
    dispatch({
        type: ActionType.SET_MAIN_LOADER,
        payload
    })
}

export const setBookingTimer = payload => dispatch => {
    dispatch({
        type: ActionType.SET_BOOKING_TIMER,
        payload
    })
}