export const localStorage = {
    set: (location, value) => {
        try {
            const serializedValue = JSON.stringify(value);
            localStorage.setItem(location, serializedValue);
        } catch (e) {
            console.log(e);
        }
    },
    get: (location) => {
        try {
            const serializedValue = localStorage.getItem(location);
            if (serializedValue === null) return undefined;
            return JSON.parse(serializedValue);
        } catch (e) {
            console.log(e);
            return undefined;
        }
    },
    remove: (location) => {
        try {
            if (location !== null) localStorage.removeItem(location);
        } catch (e) {
            console.log(e);
        }
    }
}

