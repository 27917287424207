import { BASE_URL } from '../../helpers'
import { capitalize } from '../../helpers/methods'

import { Counter } from '../counter'
import './style.scss'

/**
* @author
* @function Ticket
* @param {Object} ticket - Ticket object.
* @param {Function} onSelectTicket - Callback function to handle ticket selection.
* @param {Boolean} disableTicket - Disable all ticket actions.
* @param {Boolean} disableTicketIncrement - Disable ticket increment.
*/

export const Ticket = ({ ticket = {}, onSelectTicket, disableTicketIncrement = {}, disableTicket = false, disableTicketDecrement = false }) => {

    const getPrice = () => {
        if (ticket.free)
            return <>FREE</>
        else
            return <>{ticket.currencySymbol}{ticket.price}</>
    }

    const handleTicketSelection = (value) => {
        ticket.numberOfTickets = value;
        onSelectTicket(ticket);
    }

    const getTicketCounters = (saleStatus) => {
        switch (saleStatus) {
            case 'AVAILABLE':
                return <div className="counter-container">

                    {/* maxLimit={ticket.noOfTicketsAvailable} */}

                    <Counter
                        value={ticket.numberOfTickets ? ticket.numberOfTickets : 0}
                        maxLimit={ticket.limitTickets ? (ticket.maxBuyLimit && ticket.noOfTicketsAvailable > ticket.maxBuyLimit ? ticket.maxBuyLimit : ticket.noOfTicketsAvailable) : null}
                        minLimit={ticket.limitTickets ? ticket.minBuyLimit : 1}
                        onChangeCounter={handleTicketSelection}
                        disableIncrement={disableTicketIncrement.value}
                        disableIncrementReason={disableTicketIncrement.message}
                        disableDecrement={disableTicketDecrement}
                        disable={disableTicket}
                        name={ticket.name}
                    />
                </div>

            case 'SOLD_OUT':
                return <div className="counter-container sold-out">
                    SOLD OUT
                </div>

            case 'NOT_AVAILABLE':
                return <div className="counter-container not-available">
                    Not available right now, check after sometimes.
                </div>
            default:
                return <></>
        }
    }

    return (
        <>
            <div className="ticket-card">
                <div className="card-left">
                    <div className="title-box">
                        <div className="name">
                            {ticket.name}
                        </div>
                        <div className="price">
                            {getPrice()}
                        </div>
                    </div>
                    <p>
                        {ticket.description}
                    </p>
                </div>
                <div className="card-right">
                    {getTicketCounters(ticket.saleStatus)}
                </div>
            </div>
        </>
    );
}

/**
* @author
* @function PurchasedTicket
* @param {Object} ticket - Ticket object.
* @param {Function} onTicketClick - Callback function to handle ticket selection.
* @param {String} eventId - Event id.
*/

export const PurchasedTicket = ({ ticket = {}, onTicketClick, eventId, attendeeId, ticketNo, bookingDate = {}, timeslot = {}, qrCodeId, checkInStatus }) => {

    const getQrCode = () => {
        let qrUrl = BASE_URL + `/rest/image/qr-code/${eventId}/${attendeeId}`;
        return <img src={qrUrl} alt='' className="qr-code" />
    }

    return (
        <>
            <div className="ticket-card preview" onClick={onTicketClick}>
                <div className="card-left justify-content-center">
                    <div className="d-flex justify-content-between">
                        <div className="sub-label"> Ticket {ticketNo}
                        </div>
                        {checkInStatus === 'CHECKED_IN' && <div className="text-success d-flex" style={{ fontSize: '.9rem' }}> <img src="/assets/icons/admitted-tick.svg" alt="tick" /> &nbsp;&nbsp;ADMITTED
                        </div>}
                    </div>
                    <div className="name py-2">
                        {ticket.ticketName}
                    </div>
                    <div className="date-box">
                        <div>
                            {bookingDate.day} {bookingDate.month}, {capitalize(bookingDate.dayName)}
                        </div>
                        <div>
                            {timeslot.name}
                        </div>
                        <div>
                            Qty : 1
                        </div>
                    </div>
                </div>
                <div className="preview-card-right text-center">
                    <div>
                        {getQrCode()}
                    </div>
                    <div className="pt-1 opac-60" style={{ fontSize: '0.8rem' }}>
                        {qrCodeId}
                    </div>
                </div>
            </div>
        </>
    );
}