import { ActionType } from '../helpers/constants'

const INTIAL_STATE = {
    isLoading: false,
    showHeader: false,
    showTimer: false,
    showPaymentLoader: false,
};

export const root = (state = INTIAL_STATE, { type, payload }) => {
    switch (type) {
        case ActionType.SET_MAIN_LOADER:
            return {
                ...state,
                loading: payload
            };

        case ActionType.SET_BOOKING_TIMER:
            return {
                ...state,
                showTimer: payload
            };

        case ActionType.SET_SHOW_HEADER:
            return {
                ...state,
                showHeader: payload
            };

        case ActionType.SET_MAIN_PAYMENT_LOADER:
            return {
                ...state,
                showPaymentLoader: payload
            };

        default:
            return state
    }
}