import { ActionType } from '../helpers/constants'

const INTIAL_STATE = {
    isLoading: true,
    theme: {
        primary: '#f59211',
    }
};

export const event = (state = INTIAL_STATE, { type, payload }) => {
    switch (type) {
        case ActionType.SET_EVENT_LOADER:
            return {
                ...state,
                isLoading: payload
            };
        case ActionType.SET_EVENT_DETAILS:
            return {
                ...state,
                ...payload
            };
        default:
            return state
    }
}