
import './styles.scss'

export const TicketingFooter = ({ children }) => {
    return (
        <div className="mob-actions-footer">
            {children}
        </div>
    )
}


