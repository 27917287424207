export const API = {
    getEventDetails: (eventURI) => {
        return `/rest/v1/event/recurring/${eventURI}`
    },
    getBookingDates: (eventId) => {
        return `/rest/v1/event/${eventId}/recurring/bookingDates`
    },
    getTimeslots: (eventId) => {
        return `/rest/v1/event/${eventId}/recurring/timeslots`
    },
    getTickets: (eventId, timeslotId) => {
        return `/rest/v1/event/${eventId}/recurring/timeslot/${timeslotId}/tickets`
    },
    bookTickets: (eventId) => {
        return `/rest/v1/event/${eventId}/recurring/bookTicket`
    },
    unlockTickets: (eventId, paymentId) => {
        return `/rest/v1/event/${eventId}/lockedTickets/${paymentId}`;
    },
    updateAttendeeDetails: (eventId) => {
        return `/rest/v1/event/${eventId}/recurring/purchaseTicket`
    },
    bookFreeTicket: (eventId) => {
        return `/rest/v1/event/${eventId}/recurring/free/book-ticket`
    },
    updateBillingDetails: (eventId) => {
        return `/rest/v1/event/${eventId}/recurring/billingDetails`
    },
    captureBambora: () => {
        return '/rest/v1/payment/bambora/capture'
    },
    getDetailsAfterBooking: (bookingId) => {
        return `/rest/v1/payment/charge/${bookingId}`
    },
    downloadTickets: (paymentId, eventType) => {
        return `/rest/v1/event/recurring/emails/${paymentId}?eventType=${eventType}`
    },
    resendTickets: () => {
        return `/rest/v1/event/recurring/email/resend`
    },
    getBamboraToken: () => {
        return `https://api.na.bambora.com/scripts/tokenization/tokens`
    },
}







