import Loader from "react-loader-spinner";

import './style.scss'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

export const ButtonLoader = ({ color = "#ffffff", type = "ThreeDots", isLoading, btnLabel, loadingLabel }) => {
    return (
        <>
            {isLoading ?
                <span className="d-flex justify-content-center align-items-center">
                    {loadingLabel && <span className="px-2">{loadingLabel}</span>}
                    <Loader type={type} color={color} height={24} width={25} />
                </span>
                :
                <>
                    {btnLabel}
                </>

            }
        </>
    );
}
