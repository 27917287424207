import { ActionType } from '../helpers/constants'

const INTIAL_STATE = {
    bookingDates: [],
    timeslots: [],
    tickets: [],
    timeslotLoader: true,
    bookingDatesLoader: true,
    ticketsLoader: true,
    stopFetchingDates: false,
    noBookingDatesAvailable: false

};

export const booking = (state = INTIAL_STATE, { type, payload }) => {
    switch (type) {

        case ActionType.SET_BOOKING_DATES:
            return {
                ...state,
                bookingDates: [...state.bookingDates, ...payload]
            };

        case ActionType.SET_NO_BOOKING_DATES_AVAILABLE:
            return {
                ...state,
                noBookingDatesAvailable: true
            };

        case ActionType.SET_STOP_FETCHING_DATES:
            return {
                ...state,
                stopFetchingDates: payload
            };

        case ActionType.SET_BOOKING_DATES_LOADER:
            return {
                ...state,
                bookingDatesLoader: payload
            };

        case ActionType.SET_BOOKING_DATE_ACTIVE:
            state.bookingDates[payload.currentIndex].isActive = false;
            state.bookingDates[payload.nextIndex].isActive = true;
            return {
                ...state,
                bookingDates: [...state.bookingDates]
            };

        case ActionType.SET_TIMESLOTS:
            return {
                ...state,
                timeslots: [...payload],
                tickets: []
            };

        case ActionType.SET_TIMESLOT_LOADER:
            return {
                ...state,
                timeslotLoader: payload
            };

        case ActionType.SET_TICKETS:
            return {
                ...state,
                tickets: [...payload]
            };

        case ActionType.SET_PURACHASED_TICKETS:
            state.tickets.forEach((ticket) => {
                if (payload.ticketId === ticket.id) {
                    ticket.numberOfTickets = payload.numberOfTickets
                }
            })
            return {
                ...state,
                tickets: [...state.tickets]
            };

        case ActionType.CLEAR_ALL_PURACHASED_TICKETS:
            state.tickets.forEach((ticket) => {
                ticket.numberOfTickets = 0
            })
            return {
                ...state,
                tickets: [...state.tickets]
            };

        case ActionType.SET_TIMESLOT_ACTIVE:
            state.timeslots[payload.currentIndex].isActive = false;
            state.timeslots[payload.nextIndex].isActive = true;
            return {
                ...state,
                timeslots: [...state.timeslots]
            };

        case ActionType.SET_TICKETS_LOADER:
            return {
                ...state,
                ticketsLoader: payload
            };

        default:
            return state
    }
}