import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Container, Spinner, Button } from 'react-bootstrap'

import { SelectTicket } from './selectTicket'
import { SelectTimeslot } from './selectTimeslot'
import { AttendeeDetails } from './attendeeDetails'
import { BillingDetails } from './billingDetails'
import { getEventDetails, setBookingCancelled } from '../../actions'
import { AttendeeState } from '../../helpers/constants'
import { ModalComponent } from '../../components'
import { ShowErrorMsg } from '../errorPage'
import { _unlockTickets } from "../../services";

import './style.scss'

export const TicketingPage = () => {

  const dispatch = useDispatch();
  const event = useSelector(state => state.event);
  const { bookingState, isBookingTimeExpired, payment } = useSelector(state => state.attendee);
  const toast = useSelector(state => state.toast);
  const { eventURI } = useParams();

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isBookingCancelling, setIsBookingCancelling] = useState(false);

  useEffect(() => {
    dispatch(getEventDetails({
      eventURI, secureCode: 'public'
    }))
  }, []);

  const stateProps = { 'showCancelModal': showCancelModal, 'setShowCancelModal': setShowCancelModal, 'isBookingCancelling': isBookingCancelling, 'setIsBookingCancelling': setIsBookingCancelling };

  const getBookingState = () => {
    switch (bookingState) {
      case AttendeeState.SELECT_TIMESLOT:
        return <SelectTimeslot event={event} />
      case AttendeeState.SELECT_TICKET:
        return <SelectTicket event={event} />
      case AttendeeState.GET_ATTENDEE_DETAILS:
        return <AttendeeDetails event={event} stateProps={stateProps} />
      case AttendeeState.BILLING_PAYMENT_DETAILS:
        return <BillingDetails event={event} stateProps={stateProps} />
      // case AttendeeState.FINISHED_BOOKING:
      //   return <BillingDetails event={event} />
      default:
        return <div className="page-loader">
          <Spinner animation="grow" variant="grey" />
        </div>
    }
  }

  const closeCustomerModal = () => {
    window.location.reload();
  }

  let ToastErrorMsg = "error"

  const onCancelBooking = () => {
    setIsBookingCancelling(true);
    setShowCancelModal(false);

    var params = {
      eventId: event.id,
      paymentId: payment.id,
    };

    _unlockTickets(params)
      .then(({ data }) => {
        if (data) {
          dispatch(setBookingCancelled(true));
          window.location.href = `/e/${event.eventURI}`;
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <Container className="ticketing-container" fluid="xl">
        {event.isLoading ? ((toast.type !== ToastErrorMsg) ?
          <div className="page-loader">
            <Spinner animation="grow" variant="grey" />
          </div>
          :
          <>
            <ShowErrorMsg message={toast.message} title={toast.title} />
          </>
        ) :
          <>
            {getBookingState()}
          </>
        }
        <ModalComponent
          show={isBookingTimeExpired}
          size="md"
          heading='Sorry, Time is up!'
          hideClose={true}
        >
          <div className="pb-3">
            <p style={{ fontSize: '.9rem', fontWeight: '300' }}>
              The time limit gives all attendees a fair chance to buy tickets. Please try again with the exact same tickets.
            </p>
            <div className="text-right">
              <Button onClick={closeCustomerModal} variant="primary" size="sm"> Retry Booking</Button>
            </div>
          </div>

        </ModalComponent>

        <ModalComponent
          show={showCancelModal}
          onClose={() => setShowCancelModal(false)}
          size="md"
          heading="Cancel Booking?"
        >
          <div className="pb-3">
            <p style={{ fontSize: ".9rem", fonWweight: "300" }}>
              Are you sure you want to cancel your booking?
            </p>
            <div className="text-right">
              <Button
                onClick={() => setShowCancelModal(false)}
                variant="light"
                size="sm"
                style={{ marginRight: "8px" }}
              >
                No
              </Button>
              <Button onClick={onCancelBooking} variant="primary" size="sm">
                Yes
              </Button>
            </div>
          </div>
        </ModalComponent>
      </Container>

    </>
  )
}
