import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";

import {
  Cart,
  ButtonLoader,
  TicketingFooter,
  ModalComponent,
  TimeDateSwitcher,
} from "../../components";
import {
  setAttendeeDetails,
  updateAttendeeDetails,
} from "../../actions";
import { getTotalPrice, getTotalTicketNumber, regex } from "../../helpers";

export const AttendeeDetails = ({ event, stateProps }) => {
  const dispatch = useDispatch();
  const [showCart, setShowCart] = useState(false);
  const {
    selectedTickets,
    selectedBookingDate,
    payment,
    selectedTimeslot,
    isAttendeeUpdating,
    isBookingTimeExpired
  } = useSelector((state) => state.attendee);

  const defaultValues = {};
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues,
  });

  const onSubmit = (formDetails) => {
    if (formDetails.displayName) {
      let fullName = formDetails.displayName.split(" ");
      formDetails.firstName = fullName[0];
      formDetails.lastName = fullName[fullName.length - 1];
    }
    dispatch(setAttendeeDetails(formDetails));

    let attendees = [];
    var primaryAttendeeFlag = true;

    selectedTickets.forEach((ticket) => {
      if (ticket.numberOfTickets && ticket.numberOfTickets > 0) {
        let i = ticket.numberOfTickets;
        while (i > 0) {
          attendees.push({
            splitted: true,
            primaryAttendee: primaryAttendeeFlag,
            contact: formDetails,
            purchasedTickets: [
              {
                numberOfTickets: 1,
                price: ticket.price ? ticket.price : 0,
                ticketId: ticket.id,
              },
            ],
            timeslotDetails: {
              timeslotTemplateId: selectedTimeslot.id,
              timeslotDate: selectedBookingDate.date,
            },
          });
          primaryAttendeeFlag = false;
          i--;
        }
      }
    });

    if (isBookingTimeExpired) {
      alert("Sorry! Booking time expired");
      window.location.reload();
    } else {
      dispatch(
        updateAttendeeDetails({
          paymentId: payment.id,
          eventId: event.id,
          currentUrl: `${window.location.origin}/e/${event.eventURI}`,
          attendees,
          totalPrice: getTotalPrice(selectedTickets),
        })
      );
    }
  };

  const renderProceedBillingButton = () => {
    return (
      <Button
        variant="primary"
        onClick={handleSubmit(onSubmit)}
        disabled={isAttendeeUpdating || stateProps.isBookingCancelling}
      >
        <ButtonLoader
          btnLabel="Proceed"
          isLoading={isAttendeeUpdating}
          loadingLabel="Saving"
        />
      </Button>
    );
  };

  const renderCart = ({ showDateTime }) => {
    return (
      <Cart
        selectedTickets={selectedTickets}
        isEditable={false}
        showButton={false}
        showDateTime={showDateTime}
        selectedBookingDate={selectedBookingDate}
        selectedTimeslot={selectedTimeslot}
      />
    );
  };

  return (
    <>
      <Row>
        <Col lg={7} className="pt-4">
          <TimeDateSwitcher
            selectedBookingDate={selectedBookingDate}
            selectedTimeslot={selectedTimeslot}
            classNames="d-lg-none"
          />

          <div className="form-box">
            <label className="form-title">Customer Details</label>
            <Form>
              <Form.Group controlId="">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Eg: Jone Doe"
                  aria-invalid={errors.displayName ? "true" : "false"}
                  isInvalid={errors.displayName}
                  {...register("displayName", {
                    required: "Name is required",
                    pattern: {
                      value: regex.fullName,
                      message: "Full name is required",
                    },
                  })}
                />
                <Form.Control.Feedback type="invalid" role="alert">
                  {errors.displayName?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Eg : jhon@mail.com"
                  aria-invalid={errors.email ? "true" : "false"}
                  isInvalid={errors.email}
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: regex.email,
                      message: "Invalid email address",
                    },
                  })}
                />
                <Form.Control.Feedback type="invalid" role="alert">
                  {errors.email?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="+01 00 0000000"
                  aria-invalid={errors.phone ? "true" : "false"}
                  isInvalid={errors.phone}
                  {...register("phone", {
                    required: "Phone number is required",
                    pattern: {
                      value: regex.phone,
                      message: "Invalid phone number",
                    },
                  })}
                />
                <Form.Control.Feedback type="invalid" role="alert">
                  {errors.phone?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="d-none d-lg-block">
                <Row>
                  <Col md={6} className="d-grid gap-2 ">
                    <Button
                      variant="light"
                      onClick={() => stateProps.setShowCancelModal(true)}
                      disabled={isAttendeeUpdating || stateProps.isBookingCancelling}
                    >
                      <ButtonLoader
                        btnLabel="Cancel Booking"
                        isLoading={stateProps.isBookingCancelling}
                        color="primary"
                        loadingLabel="Cancelling Booking"
                      />
                    </Button>
                  </Col>
                  <Col md={6} className="d-grid gap-2 ">
                    {renderProceedBillingButton()}
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </Col>
        <Col lg={5} className="pt-4 right-box">
          <div className="right-sticky">
            {renderCart({ showDateTime: true })}
          </div>
        </Col>
      </Row>

      <TicketingFooter>
        <div className="d-flex">
          <div style={{ paddingRight: "15px", position: "relative" }}>
            <Button variant="light" onClick={() => stateProps.setShowCancelModal(true)}>
              <img width="20px" src="/assets/icons/close.svg" alt="Cancel" />
            </Button>
          </div>
          <div style={{ paddingRight: "15px", position: "relative" }}>
            <Button variant="light" onClick={() => setShowCart(true)}>
              <img src="/assets/icons/cart.svg" alt="cart" />
              <div className="cart-badge">
                {getTotalTicketNumber(selectedTickets)}
              </div>
            </Button>
          </div>
          <div className="d-grid gap-2" style={{ width: "100%" }}>
            {renderProceedBillingButton()}
          </div>
        </div>
      </TicketingFooter>

      <ModalComponent
        show={showCart}
        size="md"
        heading="Cart"
        backdrop={true}
        onClose={() => setShowCart(false)}
      >
        {renderCart({ showDateTime: false })}
      </ModalComponent>
    </>
  );
};
