import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { message } from '../../actions'

import './style.scss'

export const Counter = ({ value = 0, disable = true, disableIncrement = false, disableDecrement = false, maxLimit = undefined, minLimit = 1, onChangeCounter, disableIncrementReason = '', disableDecrementReason = '', name }) => {

    const [count, setCount] = useState(0)
    useEffect(() => {
        setCount(value)
    }, [value]);

    useEffect(() => {
        onChangeCounter(count)
    }, [count]);

    const dispatch = useDispatch();


    const incrementCounter = () => {
        if ((maxLimit && count >= maxLimit) || disableIncrement) {
            dispatch(message({ message: disableIncrementReason }))
        }
        else {
            if (minLimit > 1 && value === 0) {
                dispatch(message({ message: `${name} has a minimum purchase of ${minLimit}.` }))
                setCount(minLimit);
            }
            else {
                setCount(value => ++value);
            }
        }
    }

    const decrementCounter = () => {
        if (disableDecrement) {
            dispatch(message({ message: disableDecrementReason }))
        }
        else if (count > 0) {
            minLimit > 1 && value === minLimit ? setCount(0) : setCount(value => --value)
        }
    }

    return (
        <>
            <div className="counter">
                <button type="button" className="btn btn-primary" title={disableDecrementReason} disabled={count <= 0 || disable} onClick={decrementCounter}>
                    -
                </button>
                <span className="value">{count}</span>

                <button type="button" className="btn btn-primary" disabled={disable} onClick={incrementCounter}>
                    +
                </button>
            </div>
        </>
    );
}
