import { localStorage } from '../helpers'

export const getAccessToken = (isNeeded) => {
    return new Promise((resolve, reject) => {
        if (isNeeded) {
            const token = localStorage.get('access_token');
            if (token) {
                resolve(token);
            }
            else {
                reject('Access token not found!');
            }
        } else {
            resolve(true);
        }
    });
};
