import { useEffect } from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'
import { useSelector, useDispatch } from 'react-redux'
import toaster from "toasted-notes"
import Loader from 'react-loader-spinner'
import MetaTags from 'react-meta-tags'
import ReactGA from 'react-ga';

import { Body, Header, Toastr } from '../../components'


import { AttendeeState, getBookingTimeLimit } from '../../helpers'
import { setAttendeeUpdateLoader, setBookingTimeExpired } from '../../actions'
import { Routes } from '../../routes'

import './style.scss'

export const App = () => {

  const event = useSelector(state => state.event);
  const root = useSelector(state => state.root);
  const toast = useSelector(state => state.toast);
  const attendee = useSelector((state) => state.attendee);

  const dispatch = useDispatch();

  // warning propmt will appear only in the middle of ticket purchase
  useEffect(() => {
    if (attendee.bookingState !== AttendeeState.SELECT_TIMESLOT && attendee.bookingState !== AttendeeState.FINISHED_BOOKING && !attendee.isBookingTimeExpired && !attendee.isBookingCancelled) {
      initBeforeUnload(true);
    } else {
      initBeforeUnload(false);
    }
  }, [attendee.bookingState, attendee.isBookingTimeExpired, attendee.isBookingCancelled]);

  (function () {
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload();
      }
    };
  })();


  // useEffect(() => {

  //   if (event.GATrackingId) {
  //     ReactGA.initialize(event.GATrackingId)
  //     ReactGA.pageview(window.location.pathname + window.location.search);
  //   }

  // }, [event.GATrackingId])


  useEffect(() => {
    if (toast.message) {
      toaster.notify(<Toastr message={toast.message} type={toast.type} title={toast.title} theme={event.theme} />, {
        duration: toast.duration,
        position: toast.position
      });
    }
  }, [toast]);

  //show warning prompt on reload and back action
  const initBeforeUnload = (showExitPrompt) => {

    window.onbeforeunload = (event) => {
      // Show prompt based on state
      if (showExitPrompt) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        return "";
      }
    };

  };

  const onTimerExpire = () => {
    dispatch(setBookingTimeExpired(true))
  }

  const onHeaderLogoClick = () => {
    window.location.href = `/e/${event.eventURI}`;
  };

  return (
    <>
      <SkeletonTheme color="#f7f7f7" highlightColor="#fcfcfc" >
        {root.showPaymentLoader &&
          <div className="root-loader">
            <div className="loader-box">
              <Loader type={'Bars'} color={event.theme.primary} height={35} width={55} className="my-2" />
              <label>
                Processing your payment.
              </label>
            </div>
          </div>
        }
        {root.showHeader &&
          <Header
            event={event}
            showTimer={root.showTimer}
            expiryTimestamp={getBookingTimeLimit}
            onExpire={onTimerExpire}
            onHeaderLogoClick={onHeaderLogoClick}
          />}
        <Body hasHeader={root.showHeader} isLoadingState={root.showPaymentLoader}>
          <Routes />
        </Body>
        <MetaTags>
          <title>{event.name}</title>
          <meta name="description" content={event.description} />
          <meta property="og:title" content={event.name} />
          <meta property="og:image" content={event.logo} />
        </MetaTags>

        {/* <ModalComponent
          show={showAlertModal}
          size="md"
          heading="YOU'ER TRYING TO REDIRECT!"
        >
          <div className="pb-3">
            <p style={{ fontSize: ".9rem", fonWweight: "300" }}>
              Are you sure you want to go back. Progress will be lost.
            </p>
            <div className="text-right">
              <Button
                onClick={cancelCustomerModal}
                variant="light"
                size="sm"
                style={{ marginRight: "3px" }}
              >
                Cancel
              </Button>
              <Button onClick={closeCustomerModal} variant="primary" size="sm">
                Complete Action
              </Button>
            </div>
          </div>
        </ModalComponent> */}

      </SkeletonTheme>
    </>
  );
}

