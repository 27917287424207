import React from 'react'
import variables from '../../variables.scss'

import { Success } from '../../assets/icons/success'
import { Error } from '../../assets/icons/error'

import './style.scss'

export const OfflineToastr = ({ showToastr }) => {
    return (
        <>
            {showToastr ? <div className="offline-toastr"> You're offline right now. Check your connection.</div> : null}
        </>
    )
}

const renderToastr = (type, message, title, theme) => {
    switch (type) {
        case 'success':
            return (
                <div className="toaster-box" style={{ borderLeft: `3px solid ${variables.success}` }}>
                    <Success fill={variables.success} className="toaster-icon" />
                    <label className="toaster-title" style={{ color: variables.sucess }}>{title}</label><br />
                    <div className="toaster-message">{message}</div>
                </div>
            );
        case 'error':
            return (
                <div className="toaster-box" style={{ borderLeft: `3px solid ${variables.danger}` }}>
                    <Error fill={variables.danger} className="toaster-icon" />
                    <label className="toaster-title" style={{ color: variables.danger }}>{title}</label><br />
                    <div className="toaster-message">{message}</div>
                </div>
            );
        case 'message':
            return (
                <div className="toaster-box" style={{ borderLeft: `3px solid ${theme.primary}`, }}>
                    <div className="toaster-message">{message}</div>
                </div>
            );
        default:
            return (
                <div className="toaster-box" style={{ borderLeft: `3px solid ${theme.primary}` }}>
                    <label className="toaster-title" style={{ color: theme.primary }}>{title}</label><br />
                    <div className="toaster-message">{message}</div>
                </div>
            );
    }
}

export const Toastr = ({ type, message = '', title = '', theme }) => {



    return renderToastr(type, message, title, theme);

}