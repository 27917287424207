import { combineReducers } from 'redux';

import { root } from './rootReducer'
import { booking } from './bookingReducer'
import { event } from './eventReducer'
import { attendee } from './attendeeReducer'
import { toast } from './toastReducer'




const rootReducer = combineReducers({
    root,
    booking,
    event,
    attendee,
    toast
});

export default rootReducer;