import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Button } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'


import { Ticket, Cart, TimeDateSwitcher, ButtonLoader, TicketingFooter, ModalComponent } from '../../components'
import { getTickets, setSelectedTickets, setPurchasedTickets, clearAllPurchasedTickets, bookTickets, setTimeslotActive, setSelectedTimeSlot, setBookingState, message } from '../../actions'
import { getTotalTicketNumber } from '../../helpers'

export const SelectTicket = ({ event }) => {

    const dispatch = useDispatch();
    const [showCart, setShowCart] = useState(false);
    const { tickets, ticketsLoader, timeslots } = useSelector(state => state.booking);
    const { selectedBookingDate, selectedTimeslot, selectedTickets, isBookingTickets } = useSelector(state => state.attendee);

    useEffect(() => {
        dispatch(getTickets({
            eventId: event.id,
            date: selectedBookingDate.date,
            timeslotId: selectedTimeslot.id

        }));
    }, [])

    const handleTicketSelection = (selectedTicket) => {
        let ticketAlreadyAdded = false;
        selectedTickets.forEach((ticket, index) => {
            if (selectedTicket.id === ticket.id) {
                ticket.numberOfTickets = selectedTicket.numberOfTickets;
                ticketAlreadyAdded = true;
                if (selectedTicket.numberOfTickets <= 0) {
                    selectedTickets.splice(index, 1);
                    return
                }
            }
        });

        if (!ticketAlreadyAdded && selectedTicket.numberOfTickets > 0) {
            selectedTickets.push(selectedTicket)
        }

        dispatch(setPurchasedTickets({ ticketId: selectedTicket.id, numberOfTickets: selectedTicket.numberOfTickets }))
        dispatch(setSelectedTickets(selectedTickets));
    }

    const handleClearCart = () => {
        if (!isBookingTickets) {
            dispatch(setSelectedTickets([]));
            dispatch(clearAllPurchasedTickets());
        }

    }

    const removeTicketFromCart = (ticket) => {
        if (!isBookingTickets) {
            let filteredArray = selectedTickets.filter(item => (
                ticket.id !== item.id
            ));
            dispatch(setSelectedTickets([...filteredArray]));
            dispatch(setPurchasedTickets({ ticketId: ticket.id, numberOfTickets: 0 }))
        }
    }

    const isTicketAvailable = (ticket) => {
        let totalPurchasedTicketCount = 0
        selectedTickets.forEach(ticket => {
            totalPurchasedTicketCount += ticket.numberOfTickets ? ticket.numberOfTickets : 0
        });

        if (event.ticketSettings.limit && event.ticketSettings.maximum && totalPurchasedTicketCount >= event.ticketSettings.maximum) {
            return {
                value: true,
                message: `Ticket limit reached: Sorry, you have reached the maximum allowed number of tickets per booking.`
            };
        }

        if (totalPurchasedTicketCount >= selectedTimeslot.availableQuantity) {
            return {
                value: true,
                message: `You can only choose ${totalPurchasedTicketCount} tickets per slot.`
            };
        }
        if (ticket.numberOfTickets >= ticket.noOfTicketsAvailable || (ticket.limitTickets && ticket.maxBuyLimit && ticket.numberOfTickets >= ticket.maxBuyLimit)) {
            return {
                value: true,
                message: `You can only choose ${ticket.numberOfTickets} tickets of ${ticket.name}`
            };
        }

        if (ticket.numberOfTickets <= 0 && ticket.limitTickets && ticket.minBuyLimit > 0 && totalPurchasedTicketCount + ticket.minBuyLimit > selectedTimeslot.availableQuantity) {
            return {
                value: true,
                message: `Sorry, no tickets have been selected. Only ${selectedTimeslot.availableQuantity} tickets are available for the selected timeslot, and the chosen ${ticket.name} has a minimum purchase limit of ${ticket.minBuyLimit} tickets.`
            };
        }

        if (ticket.numberOfTickets <= 0 && event.ticketSettings.limit && event.ticketSettings.maximum > 0 && totalPurchasedTicketCount + ticket.minBuyLimit > event.ticketSettings.maximum) {
            return {
                value: true,
                message: `Sorry, no tickets have been selected. Only ${event.ticketSettings.maximum} tickets are available for the selected timeslot, and the chosen ${ticket.name} has a minimum purchase limit of ${ticket.minBuyLimit} tickets.`
            };
        }

        return {
            value: false
        };
    }

    const placeOrder = () => {
        let _selectedTickets = [];

        let totalPurchasedTicketCount = 0
        selectedTickets.forEach(ticket => {
            totalPurchasedTicketCount += ticket.numberOfTickets ? ticket.numberOfTickets : 0
        });

        if (event.ticketSettings.limit && event.ticketSettings.maximum && totalPurchasedTicketCount > event.ticketSettings.maximum) {
            dispatch(message({ message: `Ticket limit reached: Sorry, you have reached the maximum allowed number of tickets per booking.` }))
        }
        else if (event.ticketSettings.limit && event.ticketSettings.minimum && totalPurchasedTicketCount < event.ticketSettings.minimum) {
            dispatch(message({ message: `Sorry, minimum ${event.ticketSettings.minimum} number of ticket(s) required for this booking.` }))
        }
        else {
            selectedTickets.forEach(ticket => {
                if (ticket.numberOfTickets && ticket.numberOfTickets > 0) {
                    _selectedTickets.push({
                        ticketId: ticket.id,
                        numberOfTickets: ticket.numberOfTickets
                    })
                }
            });

            dispatch(bookTickets({
                eventId: event.id,
                slotDate: selectedBookingDate.date,
                timeslotId: selectedTimeslot.id,
                paymentMethod: 'ONLINE',
                tickets: _selectedTickets
            }))
        }

    }

    const onChangeTimeslot = (id, index) => {
        dispatch(setTimeslotActive({
            currentIndex: selectedTimeslot.indexPosition ? selectedTimeslot.indexPosition : 0,
            nextIndex: index
        }));
        dispatch(setSelectedTimeSlot({ ...timeslots[index], indexPosition: index }));

        dispatch(getTickets({
            eventId: event.id,
            date: selectedBookingDate.date,
            timeslotId: id
        }));

        handleClearCart();

    }

    const renderPlaceOrderButton = () => {
        return (
            <Button variant="primary" onClick={placeOrder} disabled={isBookingTickets}>
                <ButtonLoader btnLabel="Place Order" isLoading={isBookingTickets} loadingLabel="Placing order" />
            </Button>
        )
    }

    const renderCart = () => {
        return (
            <Cart
                selectedTickets={selectedTickets}
                clearCart={handleClearCart}
                isEditable={true}
                clearItem={removeTicketFromCart}
            />
        )
    }

    const onChangeDate = () => {
        // dispatch(setBookingState(AttendeeState.SELECT_TIMESLOT));
        // dispatch(setSelectedTimeSlot({}));
        // handleClearCart();
        setTimeout(() => {
            window.location.reload()
        }, 100)
    }

    return (
        <>
            <Row>
                <Col lg={7} >
                    <div className="top-bar-sticky">
                        <TimeDateSwitcher
                            selectedBookingDate={selectedBookingDate}
                            selectedTimeslot={selectedTimeslot}
                            isTimeEditable={!isBookingTickets}
                            isDateEditable={!isBookingTickets}
                            timeslots={timeslots}
                            size="lg"
                            handleTimeslotChange={onChangeTimeslot}
                            handleDateChange={onChangeDate} />
                    </div>

                    {ticketsLoader ?
                        <div>
                            {[...Array(3)].map((item, index) => (
                                <div key={index}
                                    className="ticket-card"
                                >
                                    <Skeleton height={100} />
                                </div>
                            ))
                            }
                        </div>
                        :
                        <div>
                            {tickets.map((ticket, index) => (
                                <Ticket ticket={ticket} key={index} onSelectTicket={handleTicketSelection} disableTicket={isBookingTickets} disableTicketIncrement={isTicketAvailable(ticket)} />
                            ))}
                        </div>
                    }
                </Col>
                <Col lg={5} className="pt-4 right-box">
                    <div className="right-sticky">
                        {renderCart()}

                        {selectedTickets.length ?
                            <div className="d-grid gap-2">
                                {renderPlaceOrderButton()}
                            </div>
                            :
                            <></>
                        }
                    </div>
                </Col>
            </Row>
            {selectedTickets.length > 0 &&
                <TicketingFooter>
                    <div className="d-flex">
                        <div style={{ paddingRight: '15px', position: 'relative' }}>
                            <Button variant="light" onClick={() => setShowCart(true)}>
                                <img src="/assets/icons/cart.svg" alt="cart" />
                                <div className="cart-badge">{getTotalTicketNumber(selectedTickets)}</div>
                            </Button>
                        </div>
                        <div className="d-grid gap-2" style={{ width: '100%' }}>
                            {renderPlaceOrderButton()}
                        </div>
                    </div>
                </TicketingFooter>
            }

            <ModalComponent
                show={showCart}
                size="md"
                heading='Cart'
                backdrop={true}
                onClose={() => setShowCart(false)}
            >
                {renderCart()}
            </ModalComponent>
        </>
    )
}
