import { ActionType, AttendeeState } from '../helpers'
import { setBookingTimer } from './rootActions'
import { error, success } from './toastActions'

import { _bookTickets, _updateAttendeeDetails, _updateBillingDetails, _captureBambora, _getDetailsAfterBooking, _downloadTickets, _resendTickets, _bookFreeTicket } from '../services'



export const setSelectedBookingDate = params => dispatch => {
    dispatch({
        type: ActionType.SET_SELECTED_BOOKING_DATE,
        payload: params
    })
}

export const setSelectedTimeSlot = params => dispatch => {
    dispatch({
        type: ActionType.SET_SELECTED_TIMESLOT,
        payload: params
    })
}

export const setSelectedTickets = params => dispatch => {
    dispatch({
        type: ActionType.SET_SELECTED_TICKETS,
        payload: params
    })
}

export const setBookingState = params => dispatch => {
    dispatch({
        type: ActionType.SET_BOOKING_STATE,
        payload: params
    })
}

export const bookTickets = params => dispatch => {
    dispatch(setBookTicketsLoader(true));
    _bookTickets(params, dispatch)
        .then(({ data }) => {
            dispatch({
                type: ActionType.SET_PAYMENT_GATEWAY_DETAILS,
                payload: data
            })
            dispatch(setBookTicketsLoader(false));
            dispatch(setBookingTimer(true));
        })
        .catch(e => {
            dispatch(error({ message: e.message }));
            dispatch(setBookTicketsLoader(false));

        });
}

export const setBookTicketsLoader = params => dispatch => {
    dispatch({
        type: ActionType.SET_BOOK_TICKETS_LOADER,
        payload: params
    })
}

export const setAttendeeDetails = params => dispatch => {
    dispatch({
        type: ActionType.SET_ATTENDEE_DETAILS,
        payload: params
    })
}

export const updateAttendeeDetails = params => dispatch => {
    dispatch(setAttendeeUpdateLoader(true));
    _updateAttendeeDetails(params, dispatch)
        .then(() => {
            if (params.totalPrice > 0) {
                dispatch(setBookingState(AttendeeState.BILLING_PAYMENT_DETAILS))
                dispatch(setAttendeeUpdateLoader(false));
            }
            else {
                _bookFreeTicket(params, dispatch)
                    .then(({ data }) => {
                        if (data) {
                            dispatch(setBookingState(AttendeeState.FINISHED_BOOKING));
                            setTimeout(() => {
                                window.location.href = data;
                            }, 100);
                        }
                        dispatch(setAttendeeUpdateLoader(false));
                    })
                    .catch(e => {
                        dispatch(error({ message: e.message }));
                        dispatch(setAttendeeUpdateLoader(false));
                    });
            }
        })
        .catch(e => {
            dispatch(error({ message: e.message }));
            dispatch(setAttendeeUpdateLoader(false));
        });
}

export const setAttendeeUpdateLoader = params => dispatch => {
    dispatch({
        type: ActionType.SET_ATTENDEE_UPDATE_LOADER,
        payload: params
    })
}

export const setBillingDetails = params => dispatch => {
    dispatch({
        type: ActionType.SET_BILLING_DETAILS,
        payload: params
    })
}

export const updateBillingDetails = params => dispatch => {
    dispatch(setBillingUpdateLoader(true));
    _updateBillingDetails(params, dispatch)
        .then(() => {
            dispatch(setBillingUpdateLoader(false));
        })
        .catch(e => {
            dispatch(error({ message: e.message }));
            dispatch(setBillingUpdateLoader(false));
        });
}

export const setBillingUpdateLoader = params => dispatch => {
    dispatch({
        type: ActionType.SET_BILLING_UPDATE_LOADER,
        payload: params
    })
}

export const captureBambora = params => dispatch => {
    dispatch(setPaymentLoader(true));
    _captureBambora(params, dispatch)
        .then(({ data }) => {
            if (data) {
                dispatch(setBookingState(AttendeeState.FINISHED_BOOKING));
                setTimeout(() => {
                    window.location.href = data;
                }, 100);
            }
            dispatch(setPaymentLoader(false));
        })
        .catch(e => {
            dispatch(error({ message: e.message }));
            dispatch(setPaymentLoader(false));
        });
}

export const setPaymentLoader = params => dispatch => {
    dispatch({
        type: ActionType.SET_PAYMENT_LOADER,
        payload: params
    })
    dispatch({
        type: ActionType.SET_MAIN_PAYMENT_LOADER,
        payload: params
    })
}

export const setBookingTimeExpired = params => dispatch => {
    dispatch({
        type: ActionType.SET_BOOKING_TIME_EXPIRED,
        payload: params
    })
}

export const setBookingCancelled = params => dispatch => {
    dispatch({
        type: ActionType.SET_BOOKING_CANCELLED,
        payload: params
    })
}

export const getPurchasedTickets = params => dispatch => {

    if (!params.hideLoader)
        dispatch(setAttendeeLoader(true));
    _getDetailsAfterBooking(params, dispatch)
        .then(({ data }) => {
            dispatch({
                type: ActionType.UPDATE_ATTENDEE_AFTER_BOOKING,
                payload: data
            })
            dispatch(setAttendeeLoader(false));
        })
        .catch(e => {
            dispatch(error({ message: e.message }));

        });
}

export const setAttendeeLoader = params => dispatch => {
    dispatch({
        type: ActionType.SET_ATTENDEE_LOADER,
        payload: params
    })
}


export const downloadTickets = params => dispatch => {
    dispatch(setDownloadingTickets(true));
    _downloadTickets(params, dispatch)
        .then(({ data }) => {
            dispatch(setDownloadingTickets(false));
        })
        .catch(e => {
            dispatch(error({ message: e.message }));
            dispatch(setDownloadingTickets(false));
        });
}

export const setDownloadingTickets = params => dispatch => {
    dispatch({
        type: ActionType.SET_DOWNLOADING_TICKETS,
        payload: params
    })
}

export const resendTickets = params => dispatch => {
    dispatch(setResendingTickets(true));
    _resendTickets(params, dispatch)
        .then(({ data, message }) => {
            dispatch(success({ message }));
            dispatch(setResendingTickets(false));
            return data;
        })
        .catch(e => {
            dispatch(error({ message: e.message }));
            dispatch(setResendingTickets(false));
        });
}

export const setResendingTickets = params => dispatch => {
    dispatch({
        type: ActionType.SET_RESENDING_TICKETS,
        payload: params
    })
}