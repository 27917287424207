import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { BASE_URL } from '../helpers/constants'
import { ErrorPage, TicketingPage, AfterTicketingPage } from '../container'



export const Routes = ({ user }) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Router>
        <Switch>
          <Route exact path='/' component={() => {
            window.location.href = BASE_URL;
            return null;
          }} />
          <Route exact path="/e/:eventURI" component={TicketingPage} />
          <Route exact path="/e/:eventURI/:bookingId" component={AfterTicketingPage} />
          <Route path="*" > <ErrorPage errorCode="404" /> </Route>
        </Switch>
      </Router >
    </Suspense>
  )
}
