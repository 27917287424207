import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import './style.scss'


export const Timeslots = ({ timeslots, onSelect, isLoading }) => {

    const getTimeslotStyle = ({ status }) => {
        switch (status) {
            case 'AVAILABLE':
                return 'available'
            case 'NOT_AVAILABLE':
                return 'not-available'
            case 'FAST_FILLING':
                return 'fast-filling'
            default:
                return ''
        }
    }

    const popover = (
        <Popover id="popover-basic">
            <div>
                <div>
                    Tickets available
                </div>
                <div className="popup-list">
                    <div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </Popover>
    );

    return (
        <>
            {isLoading ?
                <div className="time-slot-container">
                    {[...Array(6)].map((item, index) => (
                        <div key={index}
                            className="time-slot-loader"
                        >
                            <Skeleton width={130} height={53} />
                        </div>
                    ))
                    }
                </div>
                :
                <>{
                    timeslots.length ?
                        <div className="time-slot-container">
                            {timeslots.map((slot, index) => (

                                // <OverlayTrigger placement="bottom-end" key={slot.id} overlay={popover}>
                                <button
                                    key={slot.id}
                                    title={slot.status === 'NOT_AVAILABLE' ? 'Not Available' : ''}
                                    type="button"
                                    disabled={slot.status === 'NOT_AVAILABLE'}
                                    className={`time-slot ${getTimeslotStyle(slot)} ${slot.isActive ? 'active' : ''}`}
                                    onClick={() => onSelect(slot, index)}>
                                    {slot.name}
                                </button>
                                // </OverlayTrigger>

                            ))
                            }
                        </div>
                        :
                        <small>
                            - No timeslots available -
                        </small>
                }
                </>
            }

        </>
    );
}
