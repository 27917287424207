import { useState, useEffect } from 'react'
import { Button, Col } from 'react-bootstrap'

import Dropdown from 'react-dropdown'

import 'react-dropdown/style.css'
import './style.scss'

/**
* @author
* @function TimeDateSwitcher
* @param {Object} selectedBookingDate - Selected booking object.
* @param {Object} selectedTimeslot - Selected timeslot object.
* @param {Boolean} isDateEditable - For making booking date editable/changable.
* @param {Boolean} isTimeEditable - For making timeslot editable/changable.
* @param {Function} handleTimeslotChange - Callback function which handle timeslot change and return new timeslot Id.
* @param {Function} handleDateChange - Callback function which handle date change.
* @param {Array} timeslots - All timeslots, which is mandatory to change timeslot. 
* @param {String} size - Custom size of component. - md / lg
*/

export const TimeDateSwitcher = ({
    selectedBookingDate = {},

    classNames = "",
    selectedTimeslot = {},
    isDateEditable = false,
    isTimeEditable = false,
    handleTimeslotChange,
    handleDateChange,
    timeslots = [],
    size = "md" }) => {

    const [menuOptions, setMenuOptions] = useState([]);

    useEffect(() => {
        let tempMenuOptions = timeslots.reduce((results, timeSlot) => {
            if (timeSlot.status !== 'NOT_AVAILABLE')
                results.push({ value: timeSlot.id, label: timeSlot.name })
            return results
        }, [])
        setMenuOptions(tempMenuOptions);
    }, [])

    const onChangeTimeSlot = ({ value, label }) => {
        if (selectedTimeslot.id !== value) {
            let index;
            timeslots.forEach((timeSlot, i) => {
                if (timeSlot.id === value) {
                    index = i;
                    return
                }

            })
            handleTimeslotChange(value, index);
        }
    }
    return (
        <>
            <Col className={`time-date-switcher d-flex ${classNames}`}>
                <Col md={8} sm={7} className="date-switcher">
                    <div className="switcher-label">
                        Arrival Date
                    </div>
                    <div className={`switcher-value ${size}`}>
                        {selectedBookingDate.day}  {selectedBookingDate.month}, {selectedBookingDate.dayName}
                        {
                            isDateEditable &&
                            <Button variant="link" size="sm" style={{ padding: 0, float: 'right' }} onClick={handleDateChange}> Change</Button>}
                    </div>

                </Col>
                <Col md={4} sm={5} className="time-switcher">
                    <div className="switcher-label">
                        Arrival Time
                    </div>
                    {
                        isTimeEditable && menuOptions.length ?
                            <Dropdown
                                options={menuOptions}
                                className={`switcher-value ${size}`}
                                controlClassName='dropdown-box'
                                menuClassName='slot-drop-menu'
                                onChange={onChangeTimeSlot}
                                value={selectedTimeslot.name}
                                arrowClosed={<img src="/assets/icons/down-arrow.svg" className="drop-arrow close" />}
                                arrowOpen={<img src="/assets/icons/down-arrow.svg" className="drop-arrow open" />} />
                            :
                            <div className={`switcher-value ${size}`} >
                                {selectedTimeslot.name}
                            </div>
                    }
                </Col>
            </Col>
        </>
    );
}
