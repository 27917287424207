import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import { Cart, ButtonLoader, Bambora, ModalComponent, TicketingFooter, TimeDateSwitcher } from '../../components'
import { updateBillingDetails, captureBambora, setBillingDetails, setPaymentLoader } from '../../actions'
import { getTotalTicketNumber, regex } from '../../helpers'

export const BillingDetails = ({ event, stateProps }) => {

    const dispatch = useDispatch();
    const [showCart, setShowCart] = useState(false);
    const [activeView, setActiveView] = useState('');
    const childRef = useRef();

    const { selectedTickets, selectedBookingDate, selectedTimeslot, payment, attendeeDetails, isBookingTimeExpired, isBillingUpdating, billingDetails, isPayingAmount } = useSelector(state => state.attendee);

    const defaultValues = {
        ...attendeeDetails,
        sameAsCustomer: true
    };

    const { register, handleSubmit, formState: { errors }, getValues, setValue } = useForm({
        mode: 'onTouched',
        reValidateMode: 'onChange',
        defaultValues
    });


    useEffect(() => {
        handleUpdateBillingDetails(attendeeDetails);
    }, [])

    const onSubmit = formDetails => {
        handleUpdateBillingDetails(formDetails);
    }

    const handleSuccessTransaction = ({ token, cardHolderName, postalCode }) => {
        if (isBookingTimeExpired) {
            alert('Sorry! Booking time expired');
            window.location.reload();
        }
        else {
            dispatch(captureBambora({
                token,
                cardName: cardHolderName,
                postalCode: postalCode,
                paymentId: payment.id
            }));
        }
    }

    const handleUpdateBillingDetails = (billingDetails) => {
        if (billingDetails.displayName !== attendeeDetails.displayName || billingDetails.email !== attendeeDetails.email || billingDetails.phone !== attendeeDetails.phone) {
            setValue("sameAsCustomer", false);
        }
        dispatch(setBillingDetails(billingDetails));
        dispatch(updateBillingDetails({
            paymentId: payment.id,
            eventId: event.id,
            billingDetails
        }));
        setActiveView('PAYMENT_GATEWAY')
    }

    const handleErrorTransaction = (error) => {
        hanldePaymentLoader(false);
    }

    const hanldePaymentLoader = (val) => {
        dispatch(setPaymentLoader(val));
    }

    const getPaymentGateway = (gateway) => {
        switch (gateway) {
            case 'BAMBORA_MODUURN':
                return <Bambora onSuccess={handleSuccessTransaction} onError={handleErrorTransaction} ref={childRef} event={event} stateProps={stateProps}
                    paying={isPayingAmount} setLoading={(val) => hanldePaymentLoader(val)} amountToPay={payment.totalPaymentAmountInCurrency}
                />
            default:
                return <>Wrong Payment Gateway! Please contact admin</>
        }
    }

    const getPaymentGatewayIcon = (gateway) => {
        switch (gateway) {
            case 'BAMBORA_MODUURN':
                return <img src="/assets/images/bambora-icon.png" alt="bambora" style={{ height: '35px' }}
                />
            default:
                return <></>
        }
    }

    const changeBillingDetails = () => {
        setActiveView('BILLING_DETAILS')
    }

    const handleSameAsCustomer = (e) => {

        let currentFormValues = getValues();
        if (!currentFormValues.sameAsCustomer) {
            setValue("displayName", attendeeDetails.displayName);
            setValue("email", attendeeDetails.email);
            setValue("phone", attendeeDetails.phone);
        }
        changeBillingDetails();
    }

    const callPaymentMethod = () => {
        childRef.current.handlePayAction()
    }

    const renderActionButtons = () => {
        return (
            <> {activeView === 'PAYMENT_GATEWAY' ?
                <Button variant="primary" onClick={callPaymentMethod} disabled={isPayingAmount}>
                    <ButtonLoader btnLabel={payment.totalPaymentAmountInCurrency ? `Pay ${payment.totalPaymentAmountInCurrency}` : 'Pay Now'} isLoading={isPayingAmount} loadingLabel="Paying" />
                </Button> :
                <Button type="submit" onClick={handleSubmit(onSubmit)} variant="primary" disabled={isBillingUpdating}>
                    <ButtonLoader btnLabel="Save Billing" isLoading={isBillingUpdating} loadingLabel="Saving" />
                </Button>
            }
            </>
        )
    }

    const renderCart = ({ showDateTime }) => {
        return (
            <Cart
                selectedTickets={selectedTickets}
                isEditable={false}
                showButton={false}
                showDateTime={showDateTime}
                selectedBookingDate={selectedBookingDate}
                selectedTimeslot={selectedTimeslot}
            />
        )
    }

    return (
        <>
            <Row>
                <Col lg={7} className="pt-4">
                    <TimeDateSwitcher selectedBookingDate={selectedBookingDate} selectedTimeslot={selectedTimeslot} classNames="d-lg-none" />
                    <div className="form-box">
                        <label className="form-title">
                            Billing Details
                        </label>
                        <Form.Group controlId="sameAsCustomer" style={{ marginBottom: '1.5rem' }}>
                            <Form.Check type="checkbox" label="Same as customer details"
                                {...register("sameAsCustomer", {})} onChange={(e) => handleSameAsCustomer(e)} />
                        </Form.Group>
                        {activeView === 'BILLING_DETAILS' ? <Form>
                            <Form.Group controlId="displayName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Eg: Jone Doe"
                                    aria-invalid={errors.displayName ? "true" : "false"}
                                    isInvalid={errors.displayName}
                                    {...register("displayName", {
                                        required: 'Name is required',
                                        pattern: {
                                            value: regex.fullName,
                                            message: "Full name is required"
                                        }
                                    })} />
                                <Form.Control.Feedback type="invalid" role="alert">
                                    {errors.displayName?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Eg : jhon@mail.com"
                                    aria-invalid={errors.email ? "true" : "false"}
                                    isInvalid={errors.email}
                                    {...register("email", {
                                        required: 'Email is required',
                                        pattern: {
                                            value: regex.email,
                                            message: "Invalid email address"
                                        }
                                    })} />
                                <Form.Control.Feedback type="invalid" role="alert">
                                    {errors.email?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="phone">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control type="tel" placeholder="+01 00 0000000"
                                    aria-invalid={errors.phone ? "true" : "false"}
                                    isInvalid={errors.phone}
                                    {...register("phone", {
                                        required: 'Phone number is required',
                                        pattern: {
                                            value: regex.phone,
                                            message: "Invalid phone number"
                                        }
                                    })} />
                                <Form.Control.Feedback type="invalid" role="alert">
                                    {errors.phone?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <div className="d-none d-lg-block">
                                <Row className="justify-content-end">
                                    <Col md={6} className="d-grid gap-2 ">
                                        <Button type="submit" onClick={handleSubmit(onSubmit)} variant="primary" disabled={isBillingUpdating}>
                                            <ButtonLoader btnLabel="Save Billing" isLoading={isBillingUpdating} loadingLabel="Saving" />
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Form> :
                            <div className="d-flex justify-content-between">
                                <div className="billing-details">
                                    <label>
                                        {billingDetails.displayName}
                                    </label><br />
                                    <label>
                                        {billingDetails.email}
                                    </label><br />
                                    <label>
                                        {billingDetails.phone}
                                    </label>
                                </div>
                                <div>
                                    <Button variant="link" style={{ padding: 0 }} onClick={changeBillingDetails}>Change </Button>
                                </div>
                            </div>}
                    </div>
                    <div className="form-box">
                        <label className="form-title">
                            Payment Gateway
                        </label>
                        <span style={{ float: 'right' }}>
                            {getPaymentGatewayIcon(payment.paymentGateway)}
                        </span>
                        {activeView === 'PAYMENT_GATEWAY' &&
                            getPaymentGateway(payment.paymentGateway)
                        }
                    </div>
                </Col>
                <Col lg={5} className="pt-4 right-box">
                    <div className="right-sticky">
                        {renderCart({ showDateTime: true })}
                    </div>
                </Col>
            </Row>
            <TicketingFooter>
                <div className="d-flex">
                    <div style={{ paddingRight: "15px", position: "relative" }}>
                        <Button variant="light" onClick={() => stateProps.setShowCancelModal(true)}>
                            <img width="20px" src="/assets/icons/close.svg" alt="Cancel" />
                        </Button>
                    </div>
                    <div style={{ paddingRight: '15px', position: 'relative' }}>
                        <Button variant="light" onClick={() => setShowCart(true)}>
                            <img src="/assets/icons/cart.svg" alt="cart" />
                            <div className="cart-badge">{getTotalTicketNumber(selectedTickets)}</div>
                        </Button>
                    </div>
                    <div className="d-grid gap-2" style={{ width: '100%' }}>
                        {renderActionButtons()}
                    </div>
                </div>
            </TicketingFooter>
            <ModalComponent
                show={showCart}
                size="md"
                heading='Cart'
                backdrop={true}
                onClose={() => setShowCart(false)}
            >
                {renderCart({ showDateTime: false })}
            </ModalComponent>
        </>
    )
}
