
import { API, callAPI } from '../helpers'

export const _getBookingDates = params => {
    return new Promise((resolve, reject) => {
        callAPI(API.getBookingDates(params.eventId), 'get', {
            from: params.from, to: params.to, no: params.limit
        }, {}, {}, false, false
        ).then(({ message, status, result }) => {
            resolve({ data: result });
        })
            .catch(error => {
                reject(error);
            });
    });
};

export const _getTimeslots = params => {
    return new Promise((resolve, reject) => {
        callAPI(API.getTimeslots(params.eventId), 'get', {
            date: params.date
        }, {}, {}, false, false
        ).then(({ message, status, result }) => {
            resolve({ data: result });
        })
            .catch(error => {
                reject(error);
            });
    });
};

export const _getTickets = params => {
    return new Promise((resolve, reject) => {
        callAPI(API.getTickets(params.eventId, params.timeslotId), 'get', {
            date: params.date
        }, {}, {}, false, false
        ).then(({ message, status, result }) => {
            resolve({ data: result });
        })
            .catch(error => {
                reject(error);
            });
    });
};


