import { ActionType } from '../helpers'
import { _getEventDetails } from '../services'
import { error } from './toastActions'


export const getEventDetails = params => dispatch => {
    dispatch(setEventLoader(true));
    _getEventDetails(params, dispatch)
        .then(({ data }) => {
            dispatch({
                type: ActionType.SET_EVENT_DETAILS,
                payload: data
            })
            dispatch({
                type: ActionType.SET_SHOW_HEADER,
                payload: true
            })
            dispatch(setEventLoader(false));
        })
        .catch(e => {
            dispatch(error({ message: e.message }));
        });
}

export const setEventLoader = params => dispatch => {
    dispatch({
        type: ActionType.SET_EVENT_LOADER,
        payload: params
    })
}