import { useRef } from 'react'
import Skeleton from 'react-loading-skeleton'

import './style.scss'

export const DatesSlider = ({ dates, dateSelected, loadMore, isLoading }) => {

    const dateContainer = useRef(null);

    const rightScroll = () => {
        let left = dateContainer.current.scrollLeft + 250
        dateContainer.current.scrollLeft = left;
        loadMore();
    }

    const leftScroll = () => {
        var pos = dateContainer.current.scrollLeft - 250;
        dateContainer.current.scrollLeft = pos;
    }

    const setActive = (event, index) => {
        dateSelected(index);
    }

    const getDayName = (dayName) => {
        return dayName ? dayName.substring(0, 3) : '';
    }

    return (
        <>
            <div className="calendar-strip-container">
                <button onClick={(e) => leftScroll()} className="arrow-button left" type="button">
                    &#60;
                </button>
                <div className="calendar-rows" ref={dateContainer}>
                    {
                        dates.map((day, index) => (
                            <button onClick={(e) => setActive(e, index)}
                                type="button"
                                key={day.id}
                                className={`day-box ${day.isActive ? 'active' : ''}`}
                            >
                                <label className="day">
                                    {day.day}
                                </label>
                                <label className="day-name">
                                    {getDayName(day.dayName)}
                                </label>
                            </button>
                        ))
                    }
                    {isLoading &&
                        <>
                            {[...Array(20)].map((item, index) => (
                                <div key={index}
                                    className="date-range-loader"
                                >
                                    <Skeleton width={60} height={60} />
                                </div>
                            ))
                            }
                        </>

                    }

                </div>
                <button onClick={(e) => rightScroll()} className="arrow-button right" type="button">
                    &#62;
                </button>
            </div>

        </>
    );
}
