import { ActionType, AttendeeState } from '../helpers/constants'

const INTIAL_STATE = {
    bookingState: AttendeeState.SELECT_TIMESLOT,
    selectedBookingDate: {},
    selectedTimeslot: {},
    selectedTickets: [],
    payment: {},
    attendeeDetails: {},
    billingDetails: {},
    isBookingTickets: false,
    isAttendeeUpdating: false,
    isBillingUpdating: false,
    isPayingAmount: false,
    isBookingTimeExpired: false,
    isBookingCancelled: false,
    isTicketDownloading: false,
    isLoading: false,
    splittedAttendees: [],
    isResendingTickets: false,
};

export const attendee = (state = INTIAL_STATE, { type, payload }) => {
    switch (type) {

        case ActionType.SET_SELECTED_BOOKING_DATE:
            return {
                ...state,
                selectedBookingDate: { ...payload }
            };

        case ActionType.SET_SELECTED_TIMESLOT:
            return {
                ...state,
                selectedTimeslot: { ...payload }
            };

        case ActionType.SET_SELECTED_TICKETS:
            return {
                ...state,
                selectedTickets: [...payload]
            };

        case ActionType.SET_BOOKING_STATE:
            return {
                ...state,
                bookingState: payload
            };

        case ActionType.SET_PAYMENT_GATEWAY_DETAILS:
            return {
                ...state,
                bookingState: AttendeeState.GET_ATTENDEE_DETAILS,
                payment: payload
            };

        case ActionType.SET_BOOK_TICKETS_LOADER:
            return {
                ...state,
                isBookingTickets: payload
            };

        case ActionType.SET_ATTENDEE_DETAILS:
            return {
                ...state,
                attendeeDetails: payload
            };

        case ActionType.SET_BILLING_DETAILS:
            return {
                ...state,
                billingDetails: payload
            };

        case ActionType.SET_ATTENDEE_UPDATE_LOADER:
            return {
                ...state,
                isAttendeeUpdating: payload
            };

        case ActionType.SET_BILLING_UPDATE_LOADER:
            return {
                ...state,
                isBillingUpdating: payload
            };

        case ActionType.SET_BOOKING_TIME_EXPIRED:
            return {
                ...state,
                isBookingTimeExpired: payload
            };

        case ActionType.SET_BOOKING_CANCELLED:
            return {
                ...state,
                isBookingCancelled: payload
            };


        case ActionType.SET_DOWNLOADING_TICKETS:
            return {
                ...state,
                isTicketDownloading: payload
            };

        case ActionType.UPDATE_ATTENDEE_AFTER_BOOKING:
            return {
                ...state,
                ...payload
            };

        case ActionType.SET_ATTENDEE_LOADER:
            return {
                ...state,
                isLoading: payload
            };

        case ActionType.SET_PAYMENT_LOADER:
            return {
                ...state,
                isPayingAmount: payload
            };

        case ActionType.SET_RESENDING_TICKETS:
            return {
                ...state,
                isResendingTickets: payload
            };

        default:
            return state
    }
}