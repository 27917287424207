import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Image, Button } from 'react-bootstrap'
import ReactGA from 'react-ga';
import Parser from 'html-react-parser';


import { DatesSlider, TicketingFooter, Timeslots, ModalComponent } from '../../components'
import { AttendeeState, BOOKING_DATES_LIMIT } from '../../helpers/constants'
import { getBookingDates, setBookingDateActive, getTimeslots, setTimeslotActive, setSelectedBookingDate, setSelectedTimeSlot, setBookingState } from '../../actions'
import { capitalize } from '../../helpers'

export const SelectTimeslot = ({ event }) => {

    const dispatch = useDispatch();

    const [isInitalCall, setIsInitalCall] = useState(true);

    const [packageModal, setpackageModal] = useState(false)

    const { bookingDates, timeslots, timeslotLoader, stopFetchingDates, bookingDatesLoader, noBookingDatesAvailable } = useSelector(state => state.booking);
    const { selectedBookingDate, selectedTimeslot } = useSelector(state => state.attendee);

    useEffect(() => {
        getBookingDatesHandler(null, true)
    }, [])

    useEffect(() => {
        if (bookingDates.length > 0 && isInitalCall) {
            dispatch(setSelectedBookingDate({ ...bookingDates[0], indexPosition: 0 }));
            setIsInitalCall(false)
        }
    }, [bookingDates])

    useEffect(() => {
        if (selectedBookingDate.date) {
            dispatch(getTimeslots({ eventId: event.id, date: selectedBookingDate.date }));
            dispatch(setSelectedTimeSlot({}));
        }
    }, [selectedBookingDate])

    const getBookingDatesHandler = (from, isInitalCall) => {
        dispatch(getBookingDates({
            eventId: event.id,
            from,
            isInitalCall,
            limit: BOOKING_DATES_LIMIT
        }));
    }
    const loadMoreBookingDates = () => {
        if (!stopFetchingDates && !bookingDatesLoader)
            getBookingDatesHandler(bookingDates[bookingDates.length - 1].date, false)
    }

    const dateSelectionHandler = (index) => {
        dispatch(setBookingDateActive({
            currentIndex: selectedBookingDate.indexPosition,
            nextIndex: index
        }));
        dispatch(setSelectedBookingDate({ ...bookingDates[index], indexPosition: index }));
    }

    const timeSlotSelectionHandler = (timeslot, index) => {
        dispatch(setTimeslotActive({
            currentIndex: selectedTimeslot.indexPosition ? selectedTimeslot.indexPosition : 0,
            nextIndex: index
        }));
        dispatch(setSelectedTimeSlot({ ...timeslots[index], indexPosition: index }));
    }

    const gotoSelectTicket = () => {
        dispatch(setBookingState(AttendeeState.SELECT_TICKET))
    }

    const renderNextAction = (type) => {
        if (selectedTimeslot.id) {
            if (type === 'WEB_VIEW') {
                return (
                    <div className="selected-details-box">
                        <label className="px-4">
                            {selectedBookingDate.day}  {selectedBookingDate.month}, {capitalize(selectedBookingDate.dayName)} &nbsp; &nbsp; {selectedTimeslot.name}
                        </label>
                        <Button onClick={gotoSelectTicket} className="px-4">Next</Button>
                    </div>
                )
            }
            else {
                return (
                    <div className="d-flex justify-content-between">
                        <label className="px-2">
                            {selectedBookingDate.day}  {selectedBookingDate.month}, {capitalize(selectedBookingDate.dayName)}<br />{selectedTimeslot.name}
                        </label>
                        <div>
                            <Button onClick={gotoSelectTicket} className="px-4">Next</Button>
                        </div>

                    </div>
                )
            }
        }
    }

    const getPackagedetails = () => {

        return (
            <div className="details-container custom-scroll-bar">
                {event.coverImageSmall && <Image src={event.coverImageSmall} alt="event-image" fluid style={{ width: '100%' }} />}

                {/* 6156d11446e0fb00011de164 -> prod sysco */}

                {event.id === '6141743ac9e77c0001814553' || event.id === '615401b5c9e77c0001cbb592' || event.id === '6156d11446e0fb00011de164' ?
                    <>
                        <p className="py-3" >
                            The BC Sales leadership team is looking forward to our time
                            together. Our meeting together will be fast paced and
                            productive and 100% sales focused. To achieve this, we are
                            asking you to fill out this simple form in advance of our
                            meeting. This will help to guide our time and come out with
                            some actionable objectives post meeting. Click the link below
                            to fill out and submit your pre-work form.
                            <Button variant="link" href="/assets/files/Foodie Summit BC Team Meeting Pre-Work Form.pdf" download size="sm" style={{ padding: '0 10px' }}>
                                Click Here
                            </Button>
                        </p>
                        <div className="addmission-details host-list">
                            <h6>MEETING HOSTS</h6>
                            <ul >
                                <li> Host
                                    <p>
                                        Bill Harasymchuk, Vice President of Local Sales
                                    </p>
                                </li>
                                <li> Host
                                    <p>
                                        Mark Thompson, Director of Local Sales
                                    </p>
                                </li>
                                <li> Host
                                    <p>
                                        Kevin Devlin, Director of Local Sales
                                    </p>
                                </li>
                                <li> Host
                                    <p>
                                        Amina Pereira, Director of Contract Sales
                                    </p>
                                </li>
                                <li> Host
                                    <p>
                                        Scot Adams, Director of Business Resources
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </> : event.id === '6131a6aa46e0fb00014dee02' ?
                        <>
                            <p className="py-3" >
                                All tickets include a self-guided tour brochure, access to the tea pavilion and current exhibits, access to any performances/demonstrations at the garden, and participation in daily programming. For upcoming events and programs.
                                <Button variant="link" href="https://www.nikkayuko.com/events.asp?ID=86&CatID=2" target="_blank" size="sm" style={{ padding: '0 10px' }}>
                                    Click Here
                                </Button>
                            </p>
                            <div className="addmission-details">
                                <h6> REGULAR ADMISSION <small>(Admission Only)</small></h6>
                                <ul>
                                    <li>Adult Admission <small>(Ages 17+)</small></li>
                                    <li>Kodomo (Children) Admission <small>(Ages 6-12)</small> </li>
                                    <li>Yuki/Hana (Student) Admission <small>(Ages 13-16 & Students with student ID)</small> </li>
                                    <li>Children (Ages 5 and under) <small>FREE! Must have a ticket.</small> </li>
                                </ul>
                            </div>
                            <div className="addmission-details">
                                <h6> PACKAGES</h6>
                                <ul>
                                    <li>Kodomo (Children) Package <small>(Ages 6-12)</small>
                                        <p>
                                            Includes admission and a Tomo pack (activity book, postcard, box of Pocky - chocolate/candy covered biscuits).
                                        </p>
                                    </li>
                                    <li>Yuki/Hana (Student) Package <small>(Ages 6-12)</small>
                                        <p>
                                            Includes admission and Ramune (Japanese Soda).
                                        </p>
                                    </li>
                                    <li>Tomodachi (Friendship) Package
                                        <p>
                                            Includes a minimum of 2 Admissions up to a maximum of 4 Admissions, drinks (coffee, water or tea), and Matcha Maple cookies.
                                        </p>
                                    </li>
                                    <li>Kazoku (Family) Package
                                        <p>
                                            Includes a minimum of 5 Admissions up to a maximum of 10 Admissions, drinks (coffee, water or tea), and Matcha Maple cookies.
                                        </p>
                                    </li>
                                    <li>Ohayo Gozaimasu (Good Morning) Package <small>(Available ONLY between 10am-12pm)</small>
                                        <p>
                                            Includes Admission and a Complimentary coffee, tea, or bottled water upon arrival to enjoy.
                                        </p>
                                    </li>
                                    <li> Yugata (Evening) Package<small>(Available on Fridays & Saturdays ONLY between 5pm-7pm)</small>
                                        <p>
                                            Enjoy a visit to the garden and sip a drink on the deck or as you stroll through the pathways.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <p>
                                Refreshments are not included with admission, but guests may choose to purchase from a selection of Japanese beer, sake and wine. Guests must be 18+ with a valid photo ID to purchase alcohol. Non-alcoholic beverages also available.
                            </p>
                        </> :
                        <div style={{ paddingTop: 20 }}>
                            {Parser(event.description)}
                        </div>
                }
            </div>
        )
    }

    // const websiteButtonClick = () => {
    //     if (event.GATrackingId) {
    //         ReactGA.event({
    //             category: 'Button',
    //             action: 'Webiste Viewed from Booking Page'
    //         })
    //     }
    // }

    return (
        <>
            <Col>
                <Row>
                    <Col lg={7} className="pt-4">

                        <div className="event-details-badge">
                            <div className="event-description">
                                <h5 style={{ whiteSpace: 'initial' }} title={event.name}> {event.name}</h5>
                                <small style={{ fontWeight: 400, opacity: '80%', whiteSpace: 'initial' }} title={event.locationAddress}> {event.locationAddress}  </small>
                            </div>
                            {event.websiteUrl &&
                                <div className="mob-pad d-flex">
                                    <Button variant="light" href={event.websiteUrl} target="_blank"
                                        size="sm" >
                                        Visit Website
                                    </Button>
                                    <Button variant="primary" style={{ marginLeft: 10 }} className="d-lg-none" onClick={() => setpackageModal(true)} size="sm">
                                        View Details
                                    </Button>
                                </div>
                            }

                        </div>
                        {noBookingDatesAvailable ?
                            <> No Booking date found</> :
                            <>
                                <div className="selected-date">
                                    {selectedBookingDate.month} &nbsp; {selectedBookingDate.year}
                                </div>
                                <div className="py-3">
                                    <DatesSlider
                                        dates={bookingDates}
                                        dateSelected={dateSelectionHandler}
                                        loadMore={loadMoreBookingDates}
                                        isLoading={bookingDatesLoader} />
                                </div>
                                <hr />
                                <div>
                                    <div className="pb-3 pt-2">
                                        <span className="opac-40">
                                            Choose your time slot
                                        </span>
                                        <div className="status-tags">
                                            <span className="available">
                                                Available
                                            </span>
                                            <span className="not-available">
                                                Not Available
                                            </span>
                                            {/* <span className="fast-filling">
                                            Fast-filling
                                        </span> */}
                                        </div>
                                    </div>
                                    <Timeslots timeslots={timeslots} isLoading={timeslotLoader} onSelect={timeSlotSelectionHandler} />
                                </div>
                                {renderNextAction('WEB_VIEW')}
                            </>
                        }

                    </Col>
                    <Col lg={5} className="pt-4 right-box">

                        {getPackagedetails()}

                    </Col>
                </Row>
                {
                    selectedTimeslot.id && <TicketingFooter>
                        {renderNextAction()}
                    </TicketingFooter>
                }

            </Col >
            <ModalComponent show={packageModal} size="md" onClose={() => setpackageModal(false)} backdrop={true}
                heading="PACKAGE DETAILS" closeButton={true}>
                {getPackagedetails()}
            </ModalComponent >
        </>
    )

}
