import {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useForm } from "react-hook-form";
import { regex } from '../../helpers'
import { useSelector, useDispatch } from "react-redux";

import { Col, Row, Form, Button } from "react-bootstrap";
import { ButtonLoader } from "../loaders";

import "./style.scss";
export const Bambora = forwardRef((props, ref) => {
  const { onSuccess, onError, paying, setLoading, amountToPay, event, stateProps } = props;
  const customCheckout = window.customcheckout();
  const [isFormMounted, setIsFormMounted] = useState(false);
  const [cardBrandLogo, setCardBrandLogo] = useState("none");
  const [bamboraErrors, setBamboraErrors] = useState({});

  const {
    register,
    setError,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onTouched" });
  const checkoutFormRef = useRef(null);

  const dispatch = useDispatch();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isBookingCancelling, setisBookingCancelling] = useState(false);
  const { payment, isBookingCancelled } = useSelector((state) => state.attendee);


  useEffect(() => {
    if (!isFormMounted) {
      createInputs();
      addListeners();
    }
  }, []);

  useImperativeHandle(ref, () => ({
    handlePayAction() {
      checkoutFormRef.current.dispatchEvent(new Event("submit"));
    },
  }));

  const createInputs = () => {
    var options = {
      classes: {
        base: "form-control",
        focus: "bambora-form-control",
        error: "is-invalid",
      },
      style: {
        base: {
          color: "#231f20",
          fontFamily: "Rubik",
          fontSize: "1rem",
          fontWeight: "400",
        },
        empty: {
          color: "#a7a5a6 !important",
          fontSize: "0.9rem !important",
          fontWeight: "300 !important",
        },
      },
    };

    options.placeholder = "Card number";
    customCheckout.create("card-number", options).mount("#card-number");

    options.placeholder = "CVV";
    customCheckout.create("cvv", options).mount("#card-cvv");

    options.placeholder = "MM / YY";
    customCheckout.create("expiry", options).mount("#card-expiry");
    setIsFormMounted(true);
  };

  const addListeners = () => {
    checkoutFormRef.current.addEventListener("submit", onSubmitForm);

    customCheckout.on("brand", (event) => {
      if (event.brand && event.brand !== "unknown") {
        setCardBrandLogo(
          `url(https://cdn.na.bambora.com/downloads/images/cards/${event.brand}.svg)`
        );
      }
    });

    customCheckout.on("complete", ({ field }) => {
      setFormError(field, null);
    });

    customCheckout.on("error", ({ field, message }) => {
      setFormError(field, message);
    });
  };

  const setFormError = (field, message) => {
    setBamboraErrors((prevVal) => {
      if (message === null) delete prevVal[field];
      else prevVal[field] = message;
      return { ...prevVal };
    });
  };

  // const onCancelBooking = () => {
  //     window.location.href = `/e/${event.eventURI}`;
  // };

  const onSubmitForm = (e) => {
    e.preventDefault();
    const cardHolderName = getValues("cardHolderName");
    const postalCode = getValues("postalCode");
    if (!cardHolderName || cardHolderName === "") {
      setError("cardHolderName", {
        type: "manual",
        message: "Card holder name is required",
      });
    } else if (!postalCode || postalCode === "") {
      setError("postalCode", {
        type: "manual",
        message: "Postal Code is required",
      });
    } else {
      customCheckout.createToken(({ error, token }) => {
        error ? handleError(error) : handleSuccess(token, cardHolderName, postalCode);
      });
      setLoading(true);
    }
  };

  const handleError = (error) => {
    onError(error);
  };

  const handleSuccess = (token, cardHolderName, postalCode) => {
    onSuccess({ token, cardHolderName, postalCode });
  };

  return (
    <>
      <Form ref={checkoutFormRef}>
        <Form.Group controlId="cardHolderName">
          <Form.Control
            type="text"
            placeholder="Card holder name"
            className="card-holder-name"
            aria-invalid={errors.cardHolderName ? "true" : "false"}
            isInvalid={errors.cardHolderName}
            {...register("cardHolderName", {
              required: "Card holder name is required",
            })}
          />
          <Form.Control.Feedback type="invalid" role="alert">
            {errors.cardHolderName?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="cardNumber">
          <div
            id="card-number"
            className="card-brand-image"
            style={{ backgroundImage: cardBrandLogo, paddingTop: "12px" }}
          />
          <Form.Control.Feedback type="invalid" role="alert">
            {bamboraErrors["card-number"] ? bamboraErrors["card-number"] : ""}
          </Form.Control.Feedback>
        </Form.Group>
        <Row>
          <Col>
            <Form.Group controlId="cardCVV">
              <div id="card-cvv" style={{ paddingTop: "12px" }} />
              <Form.Control.Feedback type="invalid" role="alert">
                {bamboraErrors["cvv"] ? bamboraErrors["cvv"] : ""}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="cardExpiry">
              <div id="card-expiry" style={{ paddingTop: "12px" }} />
              <Form.Control.Feedback type="invalid" role="alert">
                {bamboraErrors["expiry"] ? bamboraErrors["expiry"] : ""}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="postalCode">
          <Form.Control
            type="text"
            placeholder="Postal Code"
            className="postal-code"
            aria-invalid={errors.postalCode ? "true" : "false"}
            isInvalid={errors.postalCode}
            {...register("postalCode", {
              required: "Postal Code is required",
              pattern: {
                value: regex.postalCode,
                message: "Invalid postal code"
              }
            })}
          />
          <Form.Control.Feedback type="invalid" role="alert">
            {errors.postalCode?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <div className="d-none d-lg-block">
          <Row>
            <Col md={6} className="d-grid gap-2 ">
              <Button
                variant="light"
                onClick={() => stateProps.setShowCancelModal(true)}
                disabled={paying || stateProps.isBookingCancelling}
              >
                <ButtonLoader
                  btnLabel="Cancel Booking"
                  isLoading={stateProps.isBookingCancelling}
                  color="primary"
                  loadingLabel="Cancelling Booking"
                />
              </Button>
            </Col>
            <Col md={6} className="d-grid gap-2">
              <Button variant="primary" type="submit" disabled={paying || stateProps.isBookingCancelling}>
                <ButtonLoader
                  btnLabel={amountToPay ? `Pay ${amountToPay}` : "Pay Now"}
                  isLoading={paying}
                  loadingLabel="Paying"
                />
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
});
