import { ActionType, BOOKING_DATES_LIMIT } from '../helpers'
import { _getBookingDates, _getTimeslots, _getTickets } from '../services'
import { error } from './toastActions'


export const getBookingDates = params => dispatch => {
    dispatch(setBookingDatesLoader(true));
    _getBookingDates(params, dispatch)
        .then(({ data }) => {
            if (params.isInitalCall) {
                if (!data || data.length < 1)
                    dispatch({
                        type: ActionType.SET_NO_BOOKING_DATES_AVAILABLE,
                        payload: true
                    })
                else
                    data[0].isActive = true;
            }
            else {
                data.shift();
            }

            if (data.length < BOOKING_DATES_LIMIT - 1) {
                dispatch({
                    type: ActionType.SET_STOP_FETCHING_DATES,
                    payload: true
                })
            }
            dispatch({
                type: ActionType.SET_BOOKING_DATES,
                payload: data
            })

            dispatch(setBookingDatesLoader(false));
        })
        .catch(e => {
            dispatch(error({ message: e.message }));
        });
}

export const setBookingDatesLoader = params => dispatch => {
    dispatch({
        type: ActionType.SET_BOOKING_DATES_LOADER,
        payload: params
    })
}

export const setBookingDateActive = params => dispatch => {
    dispatch({
        type: ActionType.SET_BOOKING_DATE_ACTIVE,
        payload: params
    })
}

export const getTimeslots = params => dispatch => {
    dispatch(setTimeslotLoader(true));
    _getTimeslots(params, dispatch)
        .then(({ data }) => {
            dispatch({
                type: ActionType.SET_TIMESLOTS,
                payload: data
            })
            dispatch(setTimeslotLoader(false));
        })
        .catch(e => {
            dispatch(error({ message: e.message }));
        });
}

export const setTimeslotActive = params => dispatch => {
    dispatch({
        type: ActionType.SET_TIMESLOT_ACTIVE,
        payload: params
    })
}

export const setTimeslotLoader = params => dispatch => {
    dispatch({
        type: ActionType.SET_TIMESLOT_LOADER,
        payload: params
    })
}

export const getTickets = params => dispatch => {
    dispatch(setTicketsLoader(true));
    _getTickets(params, dispatch)
        .then(({ data }) => {
            dispatch({
                type: ActionType.SET_TICKETS,
                payload: data
            })
            dispatch(setTicketsLoader(false));
        })
        .catch(e => {
            dispatch(error({ message: e.message }));
        });
}

export const setTicketsLoader = params => dispatch => {
    dispatch({
        type: ActionType.SET_TICKETS_LOADER,
        payload: params
    })
}

export const setPurchasedTickets = params => dispatch => {
    dispatch({
        type: ActionType.SET_PURACHASED_TICKETS,
        payload: params
    })
}

export const clearAllPurchasedTickets = () => dispatch => {
    dispatch({
        type: ActionType.CLEAR_ALL_PURACHASED_TICKETS,
    })
}
