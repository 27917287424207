import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { Container, Spinner, Button, Row, Col, Image } from 'react-bootstrap'
import Moment from 'react-moment'
import { Close } from '../../assets/icons/close'
import { ButtonLoader, TicketModal, PurchasedTicket, TimeDateSwitcher } from '../../components'
import { getEventDetails, getPurchasedTickets, downloadTickets, resendTickets } from '../../actions'


import './style.scss'
import { BASE_URL } from '../../helpers'


export const AfterTicketingPage = () => {

    const dispatch = useDispatch();

    const [showTicketModal, setShowTicketModal] = useState(false);
    const [ticketSelected, setTicketSelected] = useState({});
    const event = useSelector(state => state.event);
    const { isTicketDownloading, selectedBookingDate, selectedTimeslot, splittedAttendees, payment, attendeeDetails, isLoading, isResendingTickets } = useSelector(state => state.attendee);

    const { eventURI, bookingId } = useParams();


    useEffect(() => {
        dispatch(getEventDetails({
            eventURI, secureCode: 'public'
        }))

        dispatch(getPurchasedTickets({
            bookingId
        }))
    }, []);

    const downloadAllTickets = () => {
        if (!isTicketDownloading)
            dispatch(downloadTickets({ paymentId: payment.id, eventType: event.eventType, eventName: event.name, attendeeCount: splittedAttendees.length }))
    }

    const resendAllTickets = () => {
        if (!isResendingTickets) {
            var url = `${window.location.origin}/e/${event.eventURI}`;
            dispatch(resendTickets({ bookingId, eventId: event.id, url }))
        }
    }

    const handleTicketClick = (index) => {
        setShowTicketModal(true);
        setTicketSelected({ ...splittedAttendees[index], ticketNo: index + 1, ticketPos: index });

    }

    const handleTicketNavClick = (direction) => {

        switch (direction) {
            case 'LEFT':
                if (ticketSelected.ticketPos > 0)
                    setTicketSelected({
                        ...splittedAttendees[ticketSelected.ticketPos - 1], ticketNo: ticketSelected.ticketNo - 1,
                        ticketPos: ticketSelected.ticketPos - 1
                    });
                break

            case 'RIGHT':
                const finalTicketIndex = splittedAttendees.length - 1               //index of last ticket  

                if (ticketSelected.ticketPos !== finalTicketIndex)
                    setTicketSelected({
                        ...splittedAttendees[ticketSelected.ticketPos + 1], ticketNo: ticketSelected.ticketNo + 1,
                        ticketPos: ticketSelected.ticketPos + 1
                    })
                break

            default: setTicketSelected({ ...splittedAttendees[ticketSelected.ticketPos] })

        }
    }

    const closeTicketModal = () => {
        setShowTicketModal(false);
        dispatch(getPurchasedTickets({
            bookingId,
            hideLoader: true
        }))
    }

    const getQrCode = (attendeeId) => {
        let qrUrl = BASE_URL + `/rest/image/qr-code/${event.id}/${attendeeId}`;
        return <img src={qrUrl} alt='' className="qr-code" />
    }

    const getCustomerDetails = () => {
        return (
            <div className="details-box">
                <div className="customer-details inner-box">
                    <div className="head">
                        Customer Details
                    </div>
                    <div>
                        <div className="highlight label">{isLoading ? <Skeleton /> : <>{attendeeDetails.displayName}</>}</div>
                        <div className="label">{isLoading ? <Skeleton /> : <>{attendeeDetails.email}</>}</div>
                        <div className="label">{isLoading ? <Skeleton /> : <>{attendeeDetails.phone}</>}</div>
                    </div>
                </div>
                <div className="inner-box">
                    <div className="head">
                        Order Summary
                    </div>
                    {isLoading ?
                        <div>
                            <div> <Skeleton /></div>
                            <div> <Skeleton /></div>
                            <div> <Skeleton /></div>
                        </div> :
                        <div className="order-details">
                            <div className="order-labels">
                                {
                                    payment.totalPaymentAmount > 0 && <>
                                        <label>Total Amount</label><br />
                                        <label>Payment Status</label><br /></>
                                }
                                <label>Purchased On</label><br />
                            </div>
                            <div style={{ width: '100%' }}>
                                {payment.totalPaymentAmount > 0 &&

                                    <>
                                        <div className="highlight label">{payment.totalPaymentAmountInCurrency}</div>
                                        {payment.status === 'SUCCESS' || payment.status === 'FREE' ? <label className="text-success highlight"> Paid </label> :
                                            <label className="text-danger highlight"> Not Paid </label>}
                                    </>
                                }
                                <div className=" label"> <Moment date={payment.purchaseDate} format="LLL" /></div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }

    const renderButton = ({ className }) => {
        return (
            <div className={className}>
                <button className='left-circle arrow-btn'
                    onClick={() => handleTicketNavClick('LEFT')}
                    disabled={ticketSelected.ticketPos === 0 ? true : false} >
                    <img className="button-img" src="/assets/icons/arrow-left.svg" alt="success"
                        style={{ paddingRight: '5px' }} />
                </button>
                <button className='right-circle arrow-btn' onClick={() => handleTicketNavClick('RIGHT')}
                    disabled={ticketSelected.ticketNo === splittedAttendees.length ? true : false} >

                    <img className="button-img" src="/assets/icons/arrow-left.svg" alt="success" style={{ transform: `rotate(180deg)`, paddingRight: '5px' }} />
                </button>
            </div>
        )
    }

    return (
        <>
            <Container className="ticketing-container" fluid="xl">
                {event.isLoading ?
                    <div className="page-loader">
                        <Spinner animation="grow" variant="grey" />
                    </div>
                    :
                    <>
                        {isLoading ? <>
                            <Skeleton height={95} className="my-3" />
                        </> :
                            <div className="ticket-success-badge">
                                <div className="content-box">
                                    <div className="success-tick-box">
                                        <img src="/assets/icons/check-success-animated.svg" alt="success" />
                                    </div>
                                    <div className="content-box-inner">
                                        <h5 style={{ marginBottom: '.2rem' }}>
                                            Successfully booked your tickets.
                                        </h5>
                                        <small style={{ fontWeight: 300 }}>
                                            Ticket confirmation has been sent to <span style={{ fontWeight: 400 }}>{attendeeDetails.email}</span>
                                        </small>
                                    </div>
                                </div>
                                <div className="mob-pad">
                                    <Button variant="light" size="sm" onClick={resendAllTickets} >
                                        <ButtonLoader btnLabel="Resend Confirmation" isLoading={isResendingTickets} loadingLabel="Resending" color={'#008a46'} />
                                    </Button>
                                </div>
                            </div>
                        }
                        <Row>
                            <Col lg={7} className="pt-4">
                                <div className="d-lg-none">
                                    {isLoading ? <><Skeleton height={60} className="mb-3" /></> :
                                        <TimeDateSwitcher selectedBookingDate={selectedBookingDate} selectedTimeslot={selectedTimeslot} size="lg" />
                                    }
                                </div>
                                {event.moduurnUrl &&               //show the moduurn box only if the moduurn url is coming from backend.
                                    <div className='moduurn-field'>
                                        <div className='modurrn-text'>
                                            <h5> Lorem ipsum dolor sit amet</h5>
                                            <small>Lorem ipsum dolor sit amet, consectetur adip elit, sed do eiusmod tempor.</small>
                                        </div>
                                        <a className='mob-pad' href={event.moduurnUrl} target="_blank" rel='noreferrer'>
                                            <button className='btn-moduurn' >Order Now</button>
                                        </a>
                                    </div>
                                }
                                <div className="pb-4">
                                    <div className="d-flex justify-content-between">
                                        <div className="text-uppercase">
                                            Your Tickets ({splittedAttendees.length})
                                        </div>
                                        <div>{splittedAttendees.length > 0 && <>
                                            {
                                                isTicketDownloading ? <small className="opac-50">
                                                    Downloading tickets...
                                                </small>
                                                    :
                                                    <Button variant="link" size="sm" style={{ padding: 0 }} onClick={downloadAllTickets}>
                                                        Download Tickets
                                                    </Button>}</>
                                        }
                                        </div>
                                    </div>
                                    <div>
                                        {isLoading ?
                                            <div>
                                                {[...Array(4)].map((item, index) => (
                                                    <div key={index}
                                                        className="ticket-card"
                                                    >
                                                        <Skeleton height={120} />
                                                    </div>
                                                ))
                                                }
                                            </div>
                                            : <>{
                                                splittedAttendees.map((attendee, index) => (
                                                    <PurchasedTicket
                                                        ticket={attendee.purchasedTickets[0]}
                                                        ticketNo={index + 1}
                                                        key={attendee.id}
                                                        eventId={event.id}
                                                        attendeeId={attendee.id}
                                                        bookingDate={attendee.selectedBookingDate}
                                                        timeslot={attendee.selectedTimeslot}
                                                        qrCodeId={attendee.attendeeId}
                                                        onTicketClick={() => handleTicketClick(index)}
                                                        checkInStatus={attendee.checkInStatus}
                                                    />
                                                ))
                                            }
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="d-lg-none">
                                    {getCustomerDetails()}
                                </div>
                                {event.instructions ? <div className="pb-5">
                                    <div className="text-uppercase pb-2">
                                        Instructions
                                    </div>
                                    <p className="ticket-instructions">
                                        {event.instructions}
                                    </p>
                                </div> : <></>}
                            </Col>
                            <Col lg={5} className="pt-4 right-box">
                                <div className="right-sticky">
                                    {isLoading ? <><Skeleton height={60} className="mb-3" /></> :
                                        <TimeDateSwitcher selectedBookingDate={selectedBookingDate} selectedTimeslot={selectedTimeslot} size="lg" />
                                    }
                                    {getCustomerDetails()}
                                </div>
                            </Col>
                        </Row>
                    </>
                }

                <TicketModal show={showTicketModal} size="md" onClose={closeTicketModal} backdrop={true} >
                    <div className="ticket-modal">
                        <a className="close-div" onClick={closeTicketModal}>
                            <Close fill="white" className="close-icon" />
                        </a>
                        <div className="text-center qr-container">
                            <div className="inner-qr-container">
                                <div className="ticket-title">
                                    {event.name}
                                </div>
                                <small style={{ color: 'white' }}>
                                    ( TICKET {ticketSelected.ticketNo} )
                                </small>
                                <div>
                                    {getQrCode(ticketSelected.id)}
                                </div>

                                <div className="d-none d-lg-block">
                                    {splittedAttendees.length > 1 && renderButton({ className: 'qr-button' })}
                                </div>

                                <div className=" opac-60" style={{ fontSize: '0.8rem', marginTop: '-15px' }}>
                                    {ticketSelected.attendeeId}
                                </div>

                            </div>

                        </div>
                        <div className="content-conatiner">
                            <div>
                                <TimeDateSwitcher selectedBookingDate={selectedBookingDate} selectedTimeslot={selectedTimeslot} size="lg" />
                            </div>
                            <Row>
                                <Col className="col-details">
                                    <div>
                                        <div className="ticket-modal-label">Ticket Name</div>
                                        <label>{ticketSelected.purchasedTickets && ticketSelected.purchasedTickets[0].ticketName}</label>
                                    </div>
                                </Col>
                                <Col className="col-details">
                                    <div>
                                        <div className="ticket-modal-label">Quantity</div>
                                        <label>1</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="button-padding">
                                <Col className="col-details">
                                    <div>
                                        <div className="ticket-modal-label">Booking Id</div>
                                        <label>{ticketSelected.bookingId}</label>
                                    </div>
                                </Col>
                                <Col className="col-details">
                                    <div>
                                        <div className="ticket-modal-label">Attendee Id</div>
                                        <label>{ticketSelected.attendeeId}</label>
                                    </div>
                                </Col>
                            </Row>
                            <div className="d-lg-none">
                                {splittedAttendees.length > 1 && renderButton({ className: 'qr-button-mob' })}
                            </div>
                            {event.logo &&
                                <div className="watermark" style={{ position: 'absolute', right: '20px' }}>
                                    <Image className="logo-watermark" alt="logo" src={event.logo} />
                                </div>
                            }
                        </div>
                    </div>
                </TicketModal>
            </Container>
        </>
    )
}

