export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const ActionType = {
    SET_BOOKING_TIMER: 'set_booking_timer',
    SET_MAIN_LOADER: 'set_main_loader',
    SET_SHOW_HEADER: 'set_show_header',
    SET_MAIN_PAYMENT_LOADER: 'set_main_payment_loader',

    SET_EVENT_DETAILS: 'set_event_details',
    SET_EVENT_LOADER: 'set_event_loader',

    SET_BOOKING_DATES: 'set_booking_dates',
    SET_NO_BOOKING_DATES_AVAILABLE: 'no_booking_dates_available',
    SET_STOP_FETCHING_DATES: 'set_stop_fetching_dates',
    SET_BOOKING_DATES_LOADER: 'set_booking_date_loader',
    SET_BOOKING_DATE_ACTIVE: 'set_booking_date_active',

    SET_TIMESLOTS: 'set_timeslots',
    SET_TIMESLOT_LOADER: 'set_timeslot_loader',
    SET_TIMESLOT_ACTIVE: 'set_timeslot_active',

    SET_TICKETS: 'set_tickets',
    SET_TICKETS_LOADER: 'set_tickets_loader',
    SET_PURACHASED_TICKETS: 'set_pruchased_tickets',
    CLEAR_ALL_PURACHASED_TICKETS: 'clear_all_purchased_tickets',

    SET_SELECTED_BOOKING_DATE: 'set_selected_booking_date',
    SET_SELECTED_TIMESLOT: 'set_selected_timeslot',
    SET_SELECTED_TICKETS: 'set_selected_tickets',
    SET_BOOKING_STATE: 'set_booking_state',

    SET_BOOK_TICKETS_LOADER: 'set_book_tickets_loader',
    SET_PAYMENT_GATEWAY_DETAILS: 'set_payment_gateway_details',
    SET_ATTENDEE_DETAILS: 'set_attendee_details',
    SET_BOOKING_TIME_EXPIRED: 'set_booking_time_expired',
    SET_BOOKING_CANCELLED: 'set_booking_cancelled',

    SET_BILLING_DETAILS: 'set_billing_details',
    SET_ATTENDEE_UPDATE_LOADER: 'set_attendee_update_loader',
    SET_BILLING_UPDATE_LOADER: 'set_billing_update_loader',
    SET_PAYMENT_LOADER: 'set_payment_loader',
    SET_DOWNLOADING_TICKETS: 'set_downloading_tickets',
    SET_RESENDING_TICKETS: 'set_resending_tickets',
    UPDATE_ATTENDEE_AFTER_BOOKING: 'update_attendee_after_booking',
    SET_ATTENDEE_LOADER: 'set_attendee_loader',

    TOAST_SUCCESS: 'toast_success',
    TOAST_ERROR: 'toast_error',
    TOAST_WARNING: 'toast_warning',
    TOAST_INFO: 'toast_info',
    TOAST_CLEAR: 'toast_clear',
    TOAST_MESSAGE: 'toast_message'
};

export const AttendeeState = {
    SELECT_TIMESLOT: 'select_timeslot',
    SELECT_TICKET: 'select_ticket',
    GET_ATTENDEE_DETAILS: 'get_attendee-details',
    BILLING_PAYMENT_DETAILS: 'billing_payment-details',
    FINISHED_BOOKING: 'finished_booking'
};

export const BOOKING_DATES_LIMIT = 20;

export const regex = {
    email: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$/i,
    phone: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
    postalCode: /^(\s*(\S\s*){4,})/,
    fullName: /(\w.+\s).+/i
};

export const paymentCardType = {
    AMEX: {
        type: 'amex',
        icon: '/assets/icons/paymentCards/amex.svg',
        regex: "^3[47]",
        minlength: 18,
        cvvLength: 4,
    },
    DISCOVER: {
        type: 'discover',
        icon: '/assets/icons/paymentCards/discover.svg',
        regex: "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)",
        minlength: 19,
        cvvLength: 3,
    },
    MASTERCARD: {
        type: 'mastercard',
        icon: '/assets/icons/paymentCards/mastercard.svg',
        regex: "^5[0-9]{5,}$|^222[1-9][0-9]{3,}$|^22[3-9][0-9]{4,}$|^2[3-6][0-9]{5,}$|^27[01][0-9]{4,}$|^2720[0-9]{3,}$",
        minlength: 19,
        cvvLength: 3,
    },
    VISA: {
        type: 'visa',
        icon: '/assets/icons/paymentCards/visa.svg',
        regex: "^4",
        minlength: 19,
        cvvLength: 3,
    }
};