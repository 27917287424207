import { Button } from 'react-bootstrap';
import { getTotalPrice } from '../../helpers';
import { TimeDateSwitcher } from '../timeDateSwitcher';
import './style.scss'

export const Cart = ({
    selectedTickets = [],
    clearCart,
    clearItem,
    isEditable = false,
    selectedBookingDate = {},
    selectedTimeslot = {},
    showDateTime
}) => {

    return (
        <div className="cart">
            <div className="pb-3">
                <span className="cart-title">
                    Order Summary
                </span>
                <span className="clear-link">
                    {isEditable && selectedTickets.length ? <Button variant="link" onClick={clearCart} size="sm" style={{ padding: 0 }}>Clear all</Button> : <></>}
                </span>
            </div>
            {showDateTime && <TimeDateSwitcher selectedBookingDate={selectedBookingDate} selectedTimeslot={selectedTimeslot} />}
            {selectedTickets.length ?
                <div >
                    <table className="table table-borderless">
                        <tbody style={{ fontSize: '14px' }}>
                            {selectedTickets.map((ticket, index) => (
                                <tr key={index}>
                                    <td><div style={{ maxWidth: '160px' }} className="ellipsis"> {ticket.name}</div></td>
                                    <td style={{ width: '65px' }}>x &nbsp; &nbsp;{ticket.numberOfTickets}</td>
                                    <td className="text-right">{ticket.currencySymbol}{ticket.numberOfTickets * ticket.price}
                                        {isEditable && <span className="close-item" onClick={() => clearItem(ticket)}> <img src="/assets/icons/close.svg" alt="close" /> </span>}</td>
                                </tr>
                            ))
                            }
                            <tr className="grand-total-row">
                                <td colSpan="2">Grand Total</td>
                                <td className="total">{selectedTickets[0].currencySymbol}{getTotalPrice(selectedTickets)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                :
                <div className="py-5 text-center">
                    No ticket selected
                </div>
            }
        </div >
    );
}
