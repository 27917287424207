import { useParams } from "react-router-dom"
import { Button } from 'react-bootstrap'
import { BASE_URL } from '../../helpers/constants'

import './style.scss'

export const ErrorPage = (props) => {
  let { errorCode } = useParams();
  return (
    <>
      <div className="error-page" >
        <div className='error-box'>
          <div className="error-code">
            {props.errorCode ? props.errorCode : errorCode}
          </div>
          <div className='error-description'>
            Sorry! but we couldn't find this page.
          </div>
        </div>
      </div>
    </>
  )
}

  const redirectHomePage = () => {
    window.location.href = BASE_URL;
}





export const ShowErrorMsg = (prop) => {
  return (
    <>

      <div className="event-error-page">
        <img className="error-image" src="../../assets/images/error-image.png" alt="error_image" />

        <div className='error-field'>
          <div className='error-text' >
            <h3 style={{ fontSize: "3.6rem" }}> <strong>Uh oh!</strong> </h3>
            <p className="error-msg"> Seems like the page you are trying to access isn't active right now. <br />
              Please contact the event host.</p>
          </div>
          {/* <Button style={{backgroundColor: "#6c757d", marginTop: "1em"}} onClick={redirectHomePage} size="sm"  >Go Home</Button> */}
        </div>
      </div>
    </>
  )
}
