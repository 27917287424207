import { render } from 'react-dom';
import { Provider } from 'react-redux'


import { store } from './helpers'
import { App } from './container';

import './style.scss'
import 'toasted-notes/src/styles.css';

render(
    <>
        <Provider store={store}>
            <App />
        </Provider>
    </>,
    document.getElementById('app')
);