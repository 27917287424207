import React from 'react'

export const Error = ({ fill, className }) => {
    return (
        <svg id="error" xmlns="http://www.w3.org/2000/svg" className={className} width="29.38" height="29.38" viewBox="0 0 29.38 29.38" fill={fill ? fill : 'red'}>
            <g id="Group_2655" data-name="Group 2655">
                <g id="Group_2654" data-name="Group 2654">
                    <path id="Path_2049" data-name="Path 2049" d="M14.69,0A14.69,14.69,0,1,0,29.38,14.69,14.7,14.7,0,0,0,14.69,0Zm0,27.9A13.207,13.207,0,1,1,27.9,14.69,13.23,13.23,0,0,1,14.69,27.9Z" />
                </g>
            </g>
            <g id="Group_2657" data-name="Group 2657" transform="translate(9.138 9.069)">
                <g id="Group_2656" data-name="Group 2656">
                    <path id="Path_2050" data-name="Path 2050" d="M112.586,110.752l4.31-4.31a.732.732,0,0,0-1.035-1.035l-4.31,4.31-4.31-4.31a.732.732,0,0,0-1.035,1.035l4.31,4.31-4.31,4.276a.75.75,0,0,0,0,1.035.75.75,0,0,0,1.035,0l4.31-4.31,4.31,4.31a.75.75,0,0,0,1.035,0,.75.75,0,0,0,0-1.035Z" transform="translate(-106 -105.2)" />
                </g>
            </g>
        </svg>
    )
}