
import './style.scss'
export const Body = ({ children, hasHeader, isLoadingState }) => {

    const bodyStyle = {
        paddingTop: hasHeader ? 70 : 0,
        minHeight: hasHeader ? 'calc(100vh - 70px)' : '100vh',
        transition: 'margin-left .3s ease-in-out, margin-right .3s ease-in-out',
        height: isLoadingState ? '100vh' : '100%',
        overflow: isLoadingState ? 'hidden' : 'inherit'
    }

    return (
        <div style={bodyStyle}>
            {children}
        </div>
    );
}
