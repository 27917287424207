
import { API, callAPI, setGTagScripts } from '../helpers'

export const _getEventDetails = params => {
    return new Promise((resolve, reject) => {
        callAPI(API.getEventDetails(params.eventURI), 'get', {
            secureCode: params.secureCode
        }, {}, {}, false, false
        ).then(({ message, status, result }) => {
            let event = {
                id: result.id,
                logo: result.logo,
                logoSmall: result.logoSmall,
                coverImage: result.coverImage,
                coverImageSmall: result.coverImageSmall,
                eventType: result.eventType,
                eventURI: result.eventURI,
                description: result.description,
                locationAddress: result.locationAddress,
                location: result.location,
                name: result.name,
                privateEvent: result.privateEvent,
                publishEvent: result.publishEvent,
                secureCode: result.secureCode,
                instructions: result.instructions,
                theme: {
                    primary: result.brandColor ? result.brandColor : '#008a46'
                },
                favIcon: result.favIcon ? result.favIcon : null,
                gaTrackingId: result.gaTrackingId ? result.gaTrackingId : null,
                moduurnUrl: result.moduurnUrl ? result.moduurnUrl : null,
                websiteUrl: result.websiteUrl ? result.websiteUrl : null,
                ticketSettings: result.ticketSettings ? result.ticketSettings : {}
            }

            document.documentElement.style.setProperty('--primary', event.theme.primary);

            if (event.favIcon) {
                let favicon = document.getElementById("favicon");
                favicon.href = event.favIcon;
            }

            if (event.gaTrackingId) {
                setGTagScripts(event.gaTrackingId)
            }

            resolve({ data: event });
        })
            .catch(error => {
                reject(error);
            });
    });
};
