import { Navbar, Nav, Container, Image } from 'react-bootstrap'
import { Timer } from '../timer';

import './styles.scss'

export const Header = ({ event = {}, showTimer = false, expiryTimestamp, onExpire, onHeaderLogoClick }) => {
    return (
        <Navbar collapseOnSelect fixed="top" expand="lg" className="event-header">
            <Container fluid="xl">
                <div className="nav-box">
                    <Navbar.Brand onClick={onHeaderLogoClick} style={{ cursor: 'pointer' }}>
                        {event.logo &&
                            <Image className="logo" alt="logo" src={event.logo} />
                        }
                    </Navbar.Brand>
                    <Nav>
                        {showTimer &&
                            <Timer expiryTimestamp={expiryTimestamp} onExpire={onExpire} />
                        }
                    </Nav>
                </div>
            </Container>
        </Navbar >
    );
}
