
import { API, callAPI, callXHR } from '../helpers'

export const _bookTickets = params => {
    return new Promise((resolve, reject) => {
        callAPI(API.bookTickets(params.eventId), 'post', {}, {}, {
            timeslotId: params.timeslotId,
            slotDate: params.slotDate,
            paymentMethod: params.paymentMethod,
            tickets: params.tickets
        }, false, false
        ).then(({ message, status, result }) => {
            let _payment = {
                id: result.id,
                currency: result.currency,
                currencySymbol: result.currencySymbol,
                status: result.status,
                totalPaymentAmount: result.totalPaymentAmount,
                totalPaymentAmountInCurrency: result.currencySymbol + result.totalPaymentAmount,
                paymentGateway: result.paymentGateway
            }
            resolve({ data: _payment });
        })
            .catch(error => {
                reject(error);
            });
    });
};

export const _unlockTickets = params => {
    return new Promise((resolve, reject) => {
        callAPI(API.unlockTickets(params.eventId, params.paymentId), 'delete', {}, {}, {}, false, false
        ).then(({ message, status, result }) => {
            console.log(message)
            resolve({ data: result });
        })
            .catch(error => {
                reject(error);
            });
    });
};

export const _updateAttendeeDetails = params => {
    return new Promise((resolve, reject) => {
        callAPI(API.updateAttendeeDetails(params.eventId), 'post', {}, {}, params, false, false
        ).then(({ message, status, result }) => {
            resolve();
        })
            .catch(error => {
                reject(error);
            });
    });
};

export const _bookFreeTicket = params => {
    return new Promise((resolve, reject) => {
        callAPI(API.bookFreeTicket(params.eventId), 'post', {}, {}, {
            paymentId: params.paymentId,
            status: "FREE"
        }, false, false
        ).then(({ message, status, result }) => {
            resolve({ data: result });
        })
            .catch(error => {
                reject(error);
            });
    });
};

export const _updateBillingDetails = params => {
    return new Promise((resolve, reject) => {
        callAPI(API.updateBillingDetails(params.eventId), 'post', {}, {}, params, false, false
        ).then(({ message, status, result }) => {
            resolve();
        })
            .catch(error => {
                reject(error);
            });
    });
};

export const _captureBambora = params => {
    return new Promise((resolve, reject) => {
        callAPI(API.captureBambora(), 'post', {}, {}, params, false, false
        ).then(({ message, status, result }) => {
            resolve({ data: result });
        })
            .catch(error => {
                reject(error);
            });
    });
};


export const _getDetailsAfterBooking = params => {
    return new Promise((resolve, reject) => {
        callAPI(API.getDetailsAfterBooking(params.bookingId), 'get', {}, {}, {}, false, false
        ).then(({ message, status, result }) => {
            const { paymentDetails, timeslotDetails, attendee } = result;
            var _attendee = {
                bookingState: 'finished_booking',
                selectedBookingDate: {
                    date: timeslotDetails.date,
                    day: timeslotDetails.day,
                    dayName: timeslotDetails.dayName,
                    month: timeslotDetails.month
                },
                selectedTimeslot: {
                    name: timeslotDetails.timeslotName
                },
                selectedTickets: paymentDetails.purchasedTickets,
                payment: {
                    id: paymentDetails.id,
                    currency: paymentDetails.currency,
                    currencySymbol: paymentDetails.currencySymbol,
                    purchaseDate: paymentDetails.purchaseDate,
                    status: paymentDetails.status,
                    totalPaymentAmount: paymentDetails.totalPaymentAmount,
                    totalPaymentAmountInCurrency: paymentDetails.currencySymbol + paymentDetails.totalPaymentAmount,
                    paymentGateway: paymentDetails.paymentGateway

                },
                billingDetails: paymentDetails.customerDetails,
                attendeeDetails: attendee[0].contact
            }

            _attendee.splittedAttendees = attendee.map((attendee) => {
                return {
                    id: attendee.id,
                    attendeeId: attendee.attendeeId,
                    purchasedTickets: attendee.purchasedTickets,
                    checkInStatus: attendee.checkInStatus,
                    selectedBookingDate: _attendee.selectedBookingDate,
                    selectedTimeslot: _attendee.selectedTimeslot,
                    bookingId: attendee.groupId
                }
            })

            resolve({ data: _attendee });
        })
            .catch(error => {
                reject(error);
            });
    });
};

export const _downloadTickets = params => {
    return new Promise((resolve, reject) => {
        callXHR(API.downloadTickets(params.paymentId, params.eventType), 'GET', params)
            .then(({ message, status, result }) => {
                resolve({ data: result });
            })
            .catch(error => {
                reject(error);
            });
    });
};


export const _resendTickets = params => {
    return new Promise((resolve, reject) => {
        callAPI(API.resendTickets(), 'post', {}, {}, params, false, false
        ).then(({ message, status, result }) => {
            resolve({ data: result, message });
        })
            .catch(error => {
                reject(error);
            });
    });
};

