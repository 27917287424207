import { useEffect } from 'react';
import { useTimer } from 'react-timer-hook';

import './style.scss'
import variables from '../../variables.scss'

export const Timer = ({ expiryTimestamp, onExpire }) => {

    const {
        seconds,
        minutes,
        start,
    } = useTimer({ expiryTimestamp, onExpire: onExpire, autoStart: false });

    useEffect(() => {
        start()
    }, [])

    return (
        <div className={`timer ${minutes < 1 ? 'warning' : ''}`}  >
            <img src="/assets/icons/timer.svg" alt="timer" />
            <span style={{ width: '85px' }}>
                {minutes < 10 ? `0${minutes}` : minutes} : {seconds < 10 ? `0${seconds}` : seconds} {variables.danger}
            </span>
        </div>
    );
}